<template>
  <div>
    <el-drawer
      title=""
      v-model="showAdd"
      direction="rtl"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <supplierhub-spec-form
        @close="getSpecs()"
        :lot="lot"
        :pin_notice_id="pin_notice_id"
        :if_pin_notice="if_pin_notice"
        :is_deadline_passed="itt_framework.is_deadline_passed"
      ></supplierhub-spec-form>
    </el-drawer>

    <el-drawer
      title=""
      v-model="showImport"
      direction="rtl"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <supplierhub-spec-import-form
        @close="getSpecs()"
        :lot="lot"
        :template="import_template"
      ></supplierhub-spec-import-form>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Framework {{ lot_name }} Specifications</h4>
              <div v-html="itt_framework.spec_description"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-alert
                  v-if="savedUser.is_supplier_hub_admin && supplierhub_import"
                  type="success"
                  style="margin-bottom: 10px;"
                >
                  <div>{{ supplierhub_import.message }}</div>
                </el-alert>
                <el-button
                  v-if="
                    savedUser.is_supplier_hub_admin &&
                      !itt_framework.is_archived
                  "
                  plain
                  type="warning"
                  @click="handleShowImport()"
                  size="small"
                  style="margin-left: 20px;"
                  >Import names</el-button
                >
                <el-button
                  plain
                  type="primary"
                  @click="getSpecs()"
                  size="small"
                  style="margin-left: 10px;"
                  >Refresh</el-button
                >
              </div>
              <div class="col-md-12">
                <nav
                  class="navbar shadow-none my-3 navbar-transparent float-end"
                >
                  <el-form
                    :inline="true"
                    class="demo-form-inline"
                    @submit.prevent="getSpecs($event)"
                  >
                    <el-form-item>
                      <el-button
                        type="primary"
                        @click="create()"
                        size="small"
                        v-if="
                              (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived )
                        "
                        ><i class="fa-solid fa-plus"></i>
                        {{
                          savedUser.is_supplier_hub_admin
                            ? 'Add a Specification'
                            : 'Suggest a Specification'
                        }}
                      </el-button>
                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>

            <div class="table-responsive" style="max-height: 500px;">
              <div style="padding-left: 20px;text-align: center;">
                <el-switch
                  style="display: block"
                  v-model="status"
                  active-color="#13ce66"
                  inactive-color="#adadad"
                  active-text="Official Specifications"
                  :active-value="1"
                  :inactive-value="0"
                  inactive-text="Proposed Specifications"
                >
                </el-switch>
              </div>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-sm font-weight-bolder"
                    >
                      {{
                        status
                          ? 'Framework Specifications'
                          : 'Suggested Specifications'
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="spec in specs" :key="spec.id">
                    <td class="text-sm">
                      <supplierhub-spec-view
                        :spec="spec"
                        :is_parent_archive="itt_framework.is_archived"
                        :if_pin_notice="if_pin_notice"
                        @close="getSpecs()"
                      ></supplierhub-spec-view>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="loading">Loading....</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSpecs()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'
import SupplierhubSpecView from './SupplierhubSpecView.vue'
import SupplierhubSpecForm from './SupplierhubSpecForm.vue'
import { mapState } from 'vuex'
import SupplierhubSpecImportForm from './SupplierhubSpecImportForm.vue'
import { SupplierhubLot } from '@/api_services/supplierhub_lot'

export default {
  components: {
    SupplierhubSpecView,
    SupplierhubSpecForm,
    SupplierhubSpecImportForm
  },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    lot: {
      type: Object,
      required: true,
      default: {
        name: "",
        id: 0
      }
    },
    if_pin_notice: {
      type: Number,
      required: false
    },
    pin_notice_id: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      is_deadline_passed: false,
      lot_name: null,
      showAdd: false,
      showImport: false,
      specs: [],
      term: null,
      status: 1,
      loadung: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      import_template: null,
      supplierhub_import: null
    }
  },
  watch: {
    status: function() {
      this.getSpecs()
    }
  },
  computed: {
    ...mapState(['savedUser'])
  },
  created: function() {
    this.status = this.savedUser.is_supplier_hub_admin ? 1 : 0
    this.getSpecs()
    if(this.lot != null){
      this.lot_name = this.lot.name
    }
  },
  methods: {
    getSupplierHubLot(){
      this.loading = true
      SupplierhubLot.show(9)
        .then(result => {
          console.log(result)
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleShowImport() {
      this.showImport = true
    },
    handleClose() {
      this.showImport = false
    },
    create() {
      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getSpecs()
    },
    getSpecs() {
      this.showAdd = false
      this.showImport = false
      let params = {
        page: this.meta.page,
        term: this.term,
        itt_framework_id: this.itt_framework?.id,
        status: this.status,
        lot_id: this.lot.id,
        if_pin_notice: this.if_pin_notice
      }
      this.loading = true
      SupplierhubSpec.get({
        params: params
      })
        .then(result => {
          this.import_template = result.data.import_template
          this.supplierhub_import = result.data.supplierhub_import
          if (result.data.specs) this.specs = result.data.specs
          this.meta = result.data.meta
          this.loading = false
          
        })
        .catch(error => {
     
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
