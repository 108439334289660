<template>
  <div>
    <h2 class="text-white">Card Type Rates</h2>
    <!-- <company-label-component></company-label-component> -->
    <div class="row">
      <div class="col-md-8">
        <div class="card my-3">
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="font-weight-bolder mb-0">Card Type Rates</h5>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Search" aria-describedby="button-addon2" v-model="term">
                  <button class="btn btn-primary mb-0" type="button" id="button-addon2" @click="search()">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                   <button class="btn btn-dark mb-0" @click="create()">Add</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Existing Rate</th>
                    <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Proposed Rate</th> -->
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!card_type_rates.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="card_type in card_type_rates" :key="card_type.id">
                    <td>{{ card_type.name }}</td>
                    <td>{{ parseFloat(card_type.existing_rates).toFixed(4) }} %</td>
                    <!-- <td>{{ parseFloat(card_type.proposed_rates).toFixed(4) }} %</td> -->
                    <td>
                      <a class="link me-3" @click="showEdit(card_type)" v-tooltip="'Edit'" v-allowed="'card_types,update'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(card_type)" v-tooltip="'Delete'" v-allowed="'card_types,delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getCardTypeRates()"/>
          </div>
        </div> 
      </div>
      <div class="col-md-4" v-if="original">
        <CardTypeRateForm
            v-on:close_form="closeForm"
            :key="selected.id"
            :card_type_rate_data="selected"
          ></CardTypeRateForm>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.card_type }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { CardTypeRate } from '@/api_services/card_type_rate'
import CardTypeRateForm from './CardTypeRateForm.vue'

// import CompanyLabelComponent from '../card_transactions/company_label_component.vue'
export default {
  components: { CardTypeRateForm },
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      card_type_rates:[],
      fields: ['id', 'name', 'existing_rates', 'proposed_rates', 'is_active','action'],
      selected: {
        name: null,
        twelve_months_volume_sales: null,
        existing_rates: null,
        proposed_rates: null
      },
      original: null,
      validate: ['name', 'existing_rates', 'proposed_rates'],
      to_remove: null,
      term: null,
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  created: function() {
    this.getCardTypeRates()
  },
  methods: {
    closeForm(value){
      if(value == "save") this.getCardTypeRates()
      
      this.original = null
      this.selected = null
    },
    search() {
      this.meta.page = 1
      this.getCardTypeRates()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      CardTypeRate.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getCardTypeRates()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing card type')
          console.error('error:', error.response.data)
        })
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
    },
    getCardTypeRates() {
      this.isLoading = true
      CardTypeRate.get({params: {page: this.meta.page, term: this.term}})
        .then(result => {
          if(result.data.card_type_rates)
            this.card_type_rates = result.data.card_type_rates
            this.meta = result.data.meta
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching card types')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    create() {
      this.original = {name: null, is_active: 1}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {

  }
}
</script>
