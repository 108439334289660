<template>
  <li class="nav-item">
    <a :data-bs-toggle="'collapse'" href="#" :aria-expanded="isExpanded" class="nav-link" v-bind="$attrs" @click.prevent="isExpanded = !isExpanded" :class="{active: active}">
      <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
        <slot name="icon"></slot>
      </div>
      <span class="nav-link-text ms-1">{{text}}</span>
    </a>
    <div :class="isExpanded ? 'collapse show' : 'collapse'">
      <slot name="list"></slot>
    </div>
  </li>
</template>

<script>
export default {
  name: "SidenavCollapse",
  props: {
    text: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isExpanded = this.active
    }, 500)
  }
};
</script>
