<template>
  <div>
    <div id="app" class="g-sidenav-show" :class="{ 'logged-in': !is_route_public }">
      <div class="staging text-center text-white bg-danger sticky-top" v-if="mode != 'production'">
        {{mode}} {{ is_bcp ? ' BCP':''}}
      </div>

      <div v-if="status == 1">
        <!-- <page-not-found-component></page-not-found-component> -->
      </div>
      <div v-if="status == 2">
        <div class="min-height-300 position-absolute w-100 bg-success"></div>
        <sidebar-drawer-component v-if="!is_route_public"></sidebar-drawer-component>
        <main class="position-relative max-height-vh-100 h-100" :class="!is_route_public ? 'content-adjustment':''">
          <navbar-component v-if="!is_login_route && !is_route_public"></navbar-component>
          <div class="py-4 container-fluid">
            <router-view></router-view>
          </div>
        </main>
      </div>
    </div>

    <sidebar-component v-if="!is_login_route"></sidebar-component>

  </div>
</template>

<script>
import SidebarDrawerComponent from './components/common/SidebarDrawerComponent.vue'
import SidebarVue from './components/pages/Sidebar.vue'
import NavBarComponent from './components/pages/Navbar.vue'
import PageNotFoundComponent from './components/common/PageNotFoundComponent.vue'
import store from './store/index.js'
import Swal from 'sweetalert2';
import { ProductSearchMessageApi } from '@/api_services/product_search_message'

export default {
  data() {
    return {
      correspondent_count: 0,
      is_route_public: false,
      is_login_route: false,
      status: 0,
      mode: process.env.VUE_APP_MODE,
      is_bcp: parseInt(process.env.VUE_APP_IS_BCP)
    }
  },
  components: {
    'sidebar-component': SidebarVue,
    'navbar-component': NavBarComponent,
    SidebarDrawerComponent,
    PageNotFoundComponent
  },
  watch: {
    $route(to, from) {
      this.checkIfPublic()
    },
    '$store.state.new_notifications':{
      handler(value){
        if(value){
          this.getMessagesNotifications()
        }
      },
      deep:true
    }
  },
  created: function() {
    console.log(this.is_bcp)
    this.checkIfPublic()
  },
  methods: {
    getMessagesNotifications() {
        ProductSearchMessageApi.get({type:"notification_count"})
          .then(result => {
            this.correspondent_count = result.data.product_search_message_reply_count
            this.$store.commit('updateMessageCount', this.correspondent_count)
          })
          .catch(error => {
            console.error('error:', error?.response?.data || error)
          })
          .finally(() => {
            this.$store.commit('newNotifications', false)
          })
      },
    checkIfPublic() {
      this.status = 0
      if (typeof this.$route.name === 'undefined') {
        this.status = 1
      } else {
        this.is_route_public = this.$route.meta && this.$route.meta.public
        this.status = 2
      }
      //console.log(this.$route)
    }
  },
  mounted() {
    Swal.close()
    //console.log(store.state.main_loading)
    //store.state.main_loading = false
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}

.v-notices {
  z-index: 99999 !important;
}

.staging {
  line-height: 1.5rem;
  font-size: 0.8rem;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@media print {
  body.print-element *:not(.print) {
    display: none;
  }

  @page {
    size: landscape;
  }
}

.menu-label {
  font-size: 12px;
  display: inline;
  margin-left: 15px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (min-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  .b-sidebar-outer {
    display: none;
  }

  /* #sidebar.sb-sidenav-toggled li a span.menu-label{
      /* display: none; */
  /* } */

  /* #sidebar li a span.menu-label{
      animation: 5s fadeIn;
    } */

  body.sb-sidenav-toggled #app #sidebar-wrapper .logout-label {
    display: none;
  }

  /* .logged-in {
      margin-left: -210px;
      background-color: #f5f7f8;
      transition: margin-left .8s;
    } */

  body.sb-sidenav-toggled #app.logged-in #page-content-wrapper {
    margin-left: -210px;
    background-color: #f5f7f8;
    transition: margin-left 0.8s;
    /* z-index: 1; */
  }

  body #app #page-content-wrapper {
    margin-left: 0px;
    background-color: #f5f7f8;
    transition: margin-left 0.8s;
  }

  body #app #sidebar-wrapper {
    width: 270px;
    transition: width 0.8s;
  }

  #sidebar .nav-collapse {
    z-index: 1;
    background-color: #343a40;
  }

  #sidebar .nav-item:hover {
    z-index: 2;
  }
}
.content-adjustment {
  transition: all 0.2s ease-in-out;
}
@media (max-width: 1199.98px) {
    .content-adjustment {
      margin-left: 6rem !important
  }
}
@media (min-width: 1200px) {
    .content-adjustment {
      margin-left: 18.5rem
  }
}
</style>
