<template>
<div>
  <div>
    <loading :loading="loading"></loading>
  </div>
  <hr class="invisible">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-4">
          <h5>Submit Date</h5>
          <select name="" size="8" id="" multiple class="form-select form-select-sm" v-model="selected_month_dates">
            <option v-for="month in month_dates" :key="month.id" :value="month.value">{{month.text}}</option>
          </select>
        </div>
        <div class="col-md-3" v-if="funded_dates.length">
          <h5>Funded Date</h5>
          <select name="" size="8" id="" multiple class="form-select form-select-sm" v-model="selected_funded_dates">
            <option v-for="month in funded_dates" :key="month.id" :value="month">{{month}}</option>
          </select>
        </div>
        <div class="col-md-5" v-if="dba_names.length">
          <h5>Outlets/Locations</h5>
          <select name="" size="8" id="" multiple class="form-select form-select-sm" v-model="selected_dba_names">
            <option v-for="dba in dba_names" :key="dba.id" :value="dba">{{dba}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-sm-6">
          <default-info-card :icon="{ component: 'fa-solid fa-piggy-bank', background: 'bg-gradient-success'}" title="Accumulated Savings since " :description="month_dates.length ? month_dates[0].text : '' " :value="$store.state.formattedAmount(total.total_savings_from_start)" />
        </div>
        <div class="col-sm-6">
          <default-info-card :icon="{ component: 'fas fa-landmark', background: 'bg-gradient-warning'}" title="Accumulated Transactions since " :description="month_dates.length ? month_dates[0].text : ''" :value="$store.state.formattedAmount(total.total_processed_transaction_amount_from_start)" />
        </div>
      </div>
    </div>

    <div class="col-md-12" style="padding-top: 20px;text-align:left">

      <button class="btn bg-gradient-secondary btn-lg" type="button" @click="createSummarizedData()" :disabled="in_progress">Search {{ search_status == 1 ? 'again' : '' }}</button>
      <div>
        <p v-if="in_progress">An active search inquiry is currently in progress. Kindly await the results <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
        </p>
        <p v-if="search_status == 1 && month_dates.length">Loading result ... <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
        </p>
        <p v-if="search_status == 3">An error occured while searching. Please try again.</p>
        <p v-if="search_status == 0 && !in_progress">No data found!</p>
        <p class="small-note" v-if="fetch_times >= max_fetch_time">System is taking too long to fetch your data. You can try to reload the page</p>
      </div>
    </div>

  </div>
  <hr class="invisible">
  <div style="min-height: 400px">
    <new-monthly-collection-view-component :multiplier="selected_month_dates.length" :company_id="$helpers.getCompany().id" :monthly_collection="monthly_collection" v-if="monthly_collection &&  search_status == 2"></new-monthly-collection-view-component>
  </div>
</div>
</template>

<script>
import {
  MonthlyCollection
} from '@/api_services/monthly_collection'
import {
  CardTransaction
} from '@/api_services/card_transaction'

// import { Company } from '@/api_services/company'
import * as moment from 'moment';
import NewMonthlyCollectionViewComponent from './NewMonthlyCollectionViewComponent';

// import BarChart from '@/components/charts/BarChart.js'
import DefaultInfoCard from '@/components/common/DefaultInfoCard'

export default {
  components: {
    NewMonthlyCollectionViewComponent,
    DefaultInfoCard
  },

  // components: {BarChart},
  data() {
    return {
      in_progress: false,
      form: {
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD')
      },
      month_dates: [],
      funded_dates: [],
      dba_names: [],
      fields: ['month', 'net_savings'],
      collections: [],
      datacollection: null,
      company_id: null,
      selected_month_dates: [], // Array reference
      selected_funded_dates: [],
      selected_dba_names: [],
      loading: true,
      monthly_collection: null,
      search_status: 0,
      fetch_times: 0,
      max_fetch_time: 50,
      total: {
        total_processed_transaction_amount_from_start: 0,
        total_savings_from_start: 0
      }
    }
  },
  watch: {
    selected_month_dates() {
      this.funded_dates = []
      this.dba_names = []
      this.selected_funded_dates = []
      this.selected_dba_names = []
    },
    selected_funded_dates() {
      // this.dba_names = []
      this.selected_dba_names = []
    }
  },
  created: function () {
    this.getFirstAndLastDate()
    // this.getData()
    // this.getCompany()
  },
  computed: {
    hasPending() {
      var pending = false

      this.collections.forEach(function (collection) {
        if (collection.status == 1) pending = true
      })

      return pending
    }
  },
  methods: {
    createSummarizedData() {
      this.loading = true
      this.fetch_times = 0
      this.search_status = 1
      MonthlyCollection.createQuerySummary({
          submit_dates: this.selected_month_dates,
          funded_dates: this.selected_funded_dates,
          dba_names: this.selected_dba_names
        }).then(result => {
          this.loading = false
          this.getSummarizedData()
        })
        .catch(error => {
          this.loading = false
          this.$toast.error('An error occured while searching')
          console.error('error:', error.response)
        }).finally(() => {
          this.loading = false
        })

      this.loading = false
    },
    delayedFetch() {
      let vm = this
      setTimeout(function () {
        if (vm.fetch_times < 50 && vm.in_progress) {
          vm.getSummarizedData()
          vm.fetch_times += 1
        }
      }, 3000)
    },
    formatFundedDates() {

      let self = this
      this.funded_dates.forEach(function (funded_date, i) {
        self.funded_dates[i] = moment(funded_date).format('YYYY-MM-DD')
      })

      this.loading = false

    },
    getSummarizedData() {
      this.in_progress = false
      MonthlyCollection.getQuerySummary({
          submit_dates: this.selected_month_dates,
          funded_dates: this.selected_funded_dates,
          dba_names: this.selected_dba_names
        })
        .then(result => {
          if (result.data.in_progress) {
            this.in_progress = true
            this.delayedFetch()
          } else {
            if (result.data.query) {
              this.in_progress = false
              if (result.data.query.data) {
                if (!this.funded_dates.length) this.funded_dates = result.data.query.data.funded_dates
                if (!this.dba_names.length) this.dba_names = result.data.query.data.dba_names
                this.formatFundedDates()
              } else {
                if (result.data.query.status > 0) {
                  this.loading = false
                  this.search_status = 0
                } else {
                  this.in_progress = true
                  this.loading = true
                  this.search_status = 1
                }

              }
              this.monthly_collection = result.data.query.data
              if (result.data.query.status > 0) this.search_status = result.data.query.status
              if (!this.selected_month_dates.length) {
                this.selected_month_dates = result.data.query.filters.submit_dates
              }

              if (this.search_status < 2) this.delayedFetch()
              this.loading = false
            }
          }

        })
        .catch(error => {
          this.$toast.error('An error occured while searching')
          console.error('error:', error)
        })
    },
    getFirstAndLastDate() {
      this.loading = true
      this.globalState.loading = true
      CardTransaction.getFirstAndLastDate({})
        .then(result => {
          if (result.data.dates) {
            this.generateMonthDates(result.data.dates)
          }

          if (result.data.result) {
            this.total = result.data.result
          }
        })
        .catch(error => {}).finally(() => {
          this.loading = false
          this.globalState.loading = false
        })
    },
    addMonthDate(date) {
      let item = {
        value: moment(date).format('YYYY-MM-DD'),
        text: moment(date).format('MMMM YYYY')
      }
      this.month_dates.push(item)
    },
    generateMonthDates(dates) {
      let months = []
      this.month_dates = []
      dates.forEach(function (date) {
        let item = {
          value: moment(date.date).format('YYYY-MM'),
          text: moment(date.date).format('MMMM YYYY')
        }
        months.push(item)
      })
      this.month_dates = months;

      this.getSummarizedData()
    },
    fillData() {
      let labels = []
      let data = []
      let self = this
      let backgroundColors = []

      this.collections.forEach(function (collection, i) {
        labels.push(self.getMonth(collection))
        data.push(collection.net_savings)
      })

      this.datacollection = {
        labels: labels,
        datasets: [{
          label: 'Comparison',
          color: '#17a2b8',
          backgroundColor: [],
          data: data
        }],
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    getMonth(item) {

      return moment(item.submit_date).format('MMMM YYYY')
    },
    refresh() {
      this.getData()
    },
    getAverageSavings() {
      var total = 0

      this.collections.forEach(function (collection) {
        total += collection.net_savings
      })

      return total / this.collections.length
    },
    getTotalSavings() {
      var total = 0;

      this.collections.forEach(function (collection) {
        total += collection.net_savings
      })

      return total
    },
    getData() {
      this.globalState.loading = true
      MonthlyCollection.get({
          params: this.form
        })
        .then(result => {
          this.collections = result.data.monthly_collections
          if (this.collections.length) this.company_id = this.collections[0].company_id
          this.fillData()
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching historical data')
          console.error('error:', error.response)
        }).finally(() => {
          this.globalState.loading = false
        })
    },
  }
}
</script>

<style scoped>

</style>
