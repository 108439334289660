<template>
  <div>
    <el-drawer
      title=""
      v-model="showAdd"
      direction="rtl"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <supplierhub-lot-form 
      @close="getLots()" 
      :lot="selected"
      :pin_notice_id="pin_notice_id"
      :itt_framework_id="itt_framework_id"
      :if_pin_notice="if_pin_notice"
      ></supplierhub-lot-form>
    </el-drawer>

    <el-drawer
      title=""
      v-model="showView" 
      direction="rtl"
      size="80%"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <supplierhub-lot-view
        @close="getLots()"
        :lot="selected"
        :itt_framework="itt_framework"
        :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id"
        :if_pin_notice="if_pin_notice"
        v-if="selected && showView"
      ></supplierhub-lot-view>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Framework Lots</h4>
              <div v-html="itt_framework.spec_description"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-button
                  plain
                  type="primary"
                  @click="getLots()"
                  size="small"
                  style="margin-left: 10px;" 
                  >Refresh</el-button
                >
              </div>
              <div class="col-md-12">
                <nav
                  class="navbar shadow-none my-3 navbar-transparent float-end"
                >
                  <el-form
                    :inline="true"
                    class="demo-form-inline"
                    @submit.prevent="getLots($event)"
                  >
                    <el-form-item>
                      <el-button
                        type="primary"
                        @click="create()"
                        size="small"
                        v-if="
                              (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived && savedUser.is_supplier_hub_admin)
                        "

                        
                        ><i class="fa-solid fa-plus"></i>
                        Create Lot
                      </el-button>
                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>

            <div class="table-responsive" style="max-height: 500px;">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-sm font-weight-bolder"
                    >
                      Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-sm font-weight-bolder"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lot in lots" :key="lot.id">
                    <td class="text-sm">
                      {{ lot.name }}
                    </td>
                    <td>
                      <el-button
                        size="small"
                        type="primary"
                        @click="handleView(lot)"
                        >Specifications</el-button
                      >
                      <el-button
                      v-if="(savedUser.is_supplier_hub_admin &&
                          itt_framework &&
                          !itt_framework.is_archived) ||
                        !itt_framework
                        "
                          size="small"
                        
                          @click="handleEdit(lot)"
                          >Edit</el-button
                        >
                        <el-button
                        v-if="
                              (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived && savedUser.is_supplier_hub_admin)
                        "
                          size="small"
                          type="danger"
                          @click="handleDelete(lot)"
                          >Delete</el-button
                        >
                    </td>
                  </tr>
                  <tr>
                    <td v-if="loading">Loading....</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getLots()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SupplierhubLot } from '@/api_services/supplierhub_lot'
import { mapState } from 'vuex'
import SupplierhubLotForm from './SupplierhubLotForm.vue'
import SupplierhubLotView from './SupplierhubLotView.vue'
import Swal from 'sweetalert2'

export default {
  components: { SupplierhubLotForm, SupplierhubLotView },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      itt_framework_id: null,
      pin_notice_id: null,
      showAdd: false,
      showImport: false,
      lots: [],
      term: null,
      status: 1,
      loadung: false,
      selected: null,
      showView: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  watch: {
    status: function() {
      this.getLots()
    }
  },
  computed: {
    ...mapState(['savedUser'])
  },
  created: function() {
    if(this.if_pin_notice == 1){
      this.pin_notice_id = this.itt_framework?.id
    }
    else{
      this.itt_framework_id = this.itt_framework?.id
    }

    this.status = this.savedUser.is_supplier_hub_admin ? 1 : 0
    this.getLots()
  },
  methods: {
    handleDelete(lot){
      this.loading = true

      let type = "itt"
      if(this.if_pin_notice == 1){
        type = "pin"
      }

      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this data. This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          SupplierhubLot.delete(lot.id, type)
          .then(resp => {
            this.getLots()
            this.$toast.success(resp?.data?.message || 'Framework Lot has been removed successfully')
          })
          .catch(error => {
            this.$toast.error('An error occured while deleting invoice')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleView(lot) {
      this.selected = Object.assign({}, lot)
      this.showView = true
    },
    handleEdit(lot) {
      this.selected = Object.assign({}, lot)
      this.showAdd = true
    },
    create() {
      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getLots()
    },
    getLots() {
      this.showAdd = false
      let params = {
        page: this.meta.page,
        term: this.term,
        itt_framework_id: this.itt_framework?.id,
        if_pin_notice: this.if_pin_notice
      }
      this.loading = true
      SupplierhubLot.get({
        params: params
      })
        .then(result => {
          if (result.data.lots) this.lots = result.data.lots
          this.meta = result.data.meta
          this.loading = false
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching lots')
          console.error('error:', error.response.data)
        })
        .finally(() => {})
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
