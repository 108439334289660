import api from './api'

const API = '/api/jaas'

export const Jaas = {
  getToken: function(params) {
    return api.get(API+'/get-token', params)
  },
  scheduleMeeting: (form) => {
    return api.createFormData(`${API}/schedule-meeting`, form)
  },
  updateMeeting: (id, form) => {
    return api.updateFormData(API+'/update-meeting/'+id, form)
  },
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  },
  updateAttendance: function(id, params) {
    return api.update(API+'/attendance/'+id, params)
  },
  delete: function(id) {
    return api.delete(API+'/'+id)
  },
}
