import api from './api'

const API = '/api/pin_notices'

export const PinNotices = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  update: function(params) {
    return api.update(`${API}/${params.id}`, params)
  },
}
