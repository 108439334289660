<template>
    <Form v-model="form" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update' : 'New' }} Specification
          </h5>
        </div>
        <div class="card-body">
          <label>Name</label>
          <quill-editor id="name" name="name" v-model="form.name" :options="editorOptions" :rules="validateRequire"></quill-editor>
          <ErrorMessage style="color:red" name="name" />

          <div style="text-align: right;">
            <el-button type="primary" native-type="submit" size="small">Submit</el-button>
          </div>
        </div>
      </div>
    </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'
import { quillEditor } from 'vue3-quill'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import $ from 'jquery'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['savedUser'])
  },
  components: {
    quillEditor
  },
  props: {
    spec: {
      type: Object,
      required: false
    },
    lot: {
      type: Object,
      required: true
    },
    if_pin_notice: {
      type: Number,
      required: false
    },
    pin_notice_id: {
      type: Number,
      required: false
    },
    is_deadline_passed: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: null
      },
      loading: false,
      editorOptions: {
        placeholder: 'Enter your text...',
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // Font styling options
          ],
        },
      },
    }
  },
  beforeUnmount() {
    this.form = {}
  },
  watch: {
    spec: function () {
      this.form = Object.assign({}, this.spec)
    }
  },
  created: function () {
    if (this.spec) this.form = Object.assign({}, this.spec)
  },
  methods: {
    validateRequire(value) {
      // if the field is empty
      if (!value) {
        return 'This field is required';
      }
      // All is good
      return true;
    },
    submit() {
      this.$loading = true

      if(this.if_pin_notice == 1){
        if(!this.is_deadline_passed){
          if(this.savedUser.is_supplier_hub_admin || this.$store.state.isUserABuyer()){
            this.form.status = 1
          }
          else{
            this.form.status = 0
          }
        }
        else{
          if(this.$store.state.isUserAnOriginator() || this.savedUser.is_supplier_hub_admin || this.$store.state.isUserABuyer()){
            this.form.status = 1
          }
        }
        this.form.if_pin_notice = this.if_pin_notice
      }

      this.form.supplierhub_lot_id = this.lot.id
      this.form.name = $("#name").text()

      let request = this.form.id
        ? SupplierhubSpec.update(this.form)
        : SupplierhubSpec.create(this.form)

      request
        .then(result => {
          this.$toast.success('Spec name has been submitted')
          this.$emit('close', true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
