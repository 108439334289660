<template>
<div>

  <div v-if="!itt_framework && $loading" >
      <UserIttFrameworksList key="component1"></UserIttFrameworksList>
  </div>
  <div v-else-if="!itt_framework" >
      <UserIttFrameworksList key="component2"></UserIttFrameworksList>
  </div>
  <div class="row" v-else>
    <div>
      <el-alert v-if="notification" :title="notification.message" type="success" effect="dark">
      </el-alert>
      <h3 class="" :class="!selected_framework ? 'text-white' : ''">
        Welcome to {{ itt_framework.name }}
        <span v-if="selected_framework && selected_framework.status == 0" style="font-size: 15px;color: #c5c5c5;
    font-style: italic;">(Archived)</span>
      </h3>

      <div class="card my-3">
        <div class="card-body">
          <h5>Welcome to the Procurement Hub!</h5>
          <div v-html="itt_framework.main_description"></div>
        </div>
      </div>

      <el-drawer v-model="showDrawer" :with-header="true" :showClose="false" direction="rtl" size="80%">
        <!-- drawer content here -->
        <supplier-hub-welcome></supplier-hub-welcome>
        <div class="text-center">
          <el-button class="btn btn-warning" type="warning" @click="showDrawer = false">CLOSE</el-button>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-button type="primary" @click="handleViewSpecsList()" v-tooltip="'View Specifications'">View Framework
        Lots</el-button>
    </div>

    <el-drawer v-model="showSpecsList" :showClose="true" size="80%" :with-header="true" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <div class="container">
        <supplierhub-lots-list :itt_framework="itt_framework"></supplierhub-lots-list>
      </div>
    </el-drawer>

    <div v-allowed="'common_files,view'" class="col-md-6">
      <common-files :itt_framework="itt_framework"></common-files>
    </div>
    <div v-allowed="'my_files,view'" class="col-md-6">
      <my-files :itt_framework="itt_framework"></my-files>
    </div>

    <div v-allowed="'questions,view'" class="col-md-6">
      <public-questions-with-answers-list :itt_framework="itt_framework"></public-questions-with-answers-list>
    </div>

    <div v-allowed="'questions,view'" class="col-md-6">
      <my-questions-list :itt_framework="itt_framework"></my-questions-list>
    </div>
  </div>
</div>
</template>

<script>
import MyQuestionsList from '../questions_and_answers/MyQuestionsList.vue'
import PublicQuestionsWithAnswersList from '../questions_and_answers/PublicQuestionsWithAnswersList.vue'
import CommonFiles from './CommonFiles.vue'
import MyFiles from './MyFiles.vue'
import SupplierHubWelcome from './SupplierHubWelcome.vue'
import {
  Notification
} from '@/api_services/notification'
import {
  IttFramework
} from '@/api_services/itt_framework'
import SupplierhubLotsList from './SupplierhubLots/SupplierhubLotsList.vue'
import { defineAsyncComponent } from 'vue'

export default {
  props: ['selected_framework'],
  components: {
    CommonFiles,
    MyFiles,
    PublicQuestionsWithAnswersList,
    MyQuestionsList,
    SupplierHubWelcome,
    SupplierhubLotsList,
    UserIttFrameworksList: defineAsyncComponent(() =>
      import('../itt_frameworks/UserIttFrameworksList.vue')
    ),
  },
  data() {
    return {
      showSpecsList: false,
      showFrameworks: false,
      showDrawer: false,
      drawer: false,
      notification: null,
      loading: true,
      itt_framework: null
    }
  },
  created: function () {
    if (this.selected_framework) {
      this.itt_framework = Object.assign({}, this.selected_framework)
    } else {
      this.getActiveFramework()
      this.getLatestNotification()
    }
  },
  methods: {
    handleViewSpecsList() {
      this.showSpecsList = true
    },
    handleViewFrameworks() {
      this.showFrameworks = true
    },
    getActiveFramework() {
      this.$loading = true
      IttFramework.activeFramework()
        .then(result => {
          this.itt_framework = result.data.itt_framework
        })
        .catch(error => {})
        .finally(() => {
          this.$loading = false
        })
    },
    getLatestNotification() {
      this.$loading = true
      Notification.latestNotification()
        .then(result => {
          this.notification = result.data.notification
        })
        .catch(error => {})
        .finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
