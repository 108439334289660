<template>
    
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h5>Procurement Project</h5>
              <div class="pb-0 card-header">
                <div class="row">
                <div class="col-md-2">
                    <el-input v-model="search_value" placeholder="Search" class="input-with-select">
                    <template #append>
                        <el-button><i class="fa fa-search"></i></el-button>
                    </template>
                    </el-input>
                </div>
                <div class="col-md-10" style="text-align: right;">
                    <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="loadProcurementProjects()">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                    </button>
                    <button type="button" class="btn btn-primary btn-sm mb-0 me-2" @click="addProject">
                    <i class="fa-solid fa-plus me-1"></i> Add
                    </button>
                </div>
                </div>
                <div class="text-end">
                </div>
            </div>
            <br>
              <div class="table-responsive">
                <table class="table table-fluid align-items-center mb-0 table-hover ">
                  <thead>
                    <tr>
                      
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Name
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Type
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Status
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!procurement_projects.length">
                      <td v-if="loading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="procurement_project in procurement_projects" :key="procurement_project.id">
                        
                        <td>{{ procurement_project.name }}</td>
                        <td>{{ procurement_project.type_name }}</td>
                        <td>{{ $filters.dateFormatFilter(procurement_project.created_at) }}</td>
                        <td>
                            <el-tag
                              v-tooltip="procurement_project.status_name"
                              :type="procurement_project.status_tag.color"
                              :effect="procurement_project.status_tag.type"
                              >{{ procurement_project.status_name }}</el-tag
                            >
                        </td>
                        <td >
                          <el-button-group>
                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Manage</span>
                              </template>

                              <el-button
                                v-allowed="'procurement_project,update'"
                                type="primary" size="small" @click="manageProject(procurement_project)" >
                                  <i class="fa fa-edit"></i></el-button>
                            </el-tooltip>

                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>View PIN</span>
                              </template>

                              <el-button
                                type="success" size="small" @click="viewPIN(procurement_project)" >
                                  <i class="fa fa-eye"></i></el-button>
                            </el-tooltip>

                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>View History</span>
                              </template>

                              <el-button
                                type="warning" size="small" @click="viewProjectHistory(procurement_project)" >
                                  <i class="fa fa-history"></i></el-button>
                            </el-tooltip>

                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Delete</span>
                              </template>

                              <el-button
                                v-allowed="'procurement_project,delete'"
                                type="danger" size="small" @click="showRemove(procurement_project)" >
                                  <i class="fa fa-trash"></i></el-button>
                            </el-tooltip>

                          </el-button-group>

                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="loadProcurementProjects()" />
            </div>
          </div>
        </div>
      </div>
    
      
      <el-dialog id="modal-remove" v-model="dialogVisible" title="Confirm Delete?" width="500" :before-close="handleClose">
        <div v-if="to_remove">
          You are about to remove this procurement project {{ to_remove.name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button v-bind:disabled="loading" type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog id="add-remove" v-model="add_modal"  width="60%">
        <ProcurementProjectsForm :key="'project_' + selected.id" @save="afterSave" @cancel="handleClose" :selected="selected"></ProcurementProjectsForm>
      </el-dialog>
    </div>

    <el-drawer
      v-model="showForm"
      :show-close="true"
      size="80%"
      direction="rtl" 
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div v-if="pin_notice" class="container">
        <PinNoticeView :view_itt="1" :selected_framework="pin_notice" ></PinNoticeView>
      </div>
    </el-drawer>

    <el-drawer
      v-model="history_drawer"
      :show-close="true"
      size="40%"
      direction="rtl" 
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div v-if="selected" class="container">
        <ProcurementProjectHistory :procurement_project_id="selected.id" :name="selected.name" ></ProcurementProjectHistory>
      </div>
    </el-drawer>

    </template>
    
    <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects'
    import ProcurementProjectsForm from './ProcurementProjectsForm.vue'
    import PinNoticeView from '../pin_notice/PinNoticeView'
    import { PinNotices } from '@/api_services/pin_notices'
    import ProcurementProjectHistory from './ProcurementProjectHistory.vue'

    export default {
      emits: ["save", "cancel"],
      components: {
        ProcurementProjectsForm, PinNoticeView, ProcurementProjectHistory
      },
      data() {
        return {
          history_drawer: false,
          pin_notice: null,
          selected: null,
          add_modal: false,
          dialogVisible: false,
          procurement_projects: [],
          to_remove: null,
          meta: {
            page: 1,
            total: 0,
            pages: 1
          },
          showForm: false,
          loading: false,
          search_value: null,
        }
      },
      watch: {
        search_value: function () {
            if (!this.search_value || this.search_value.length > 2) this.loadProcurementProjects()
        }
      },
      created: function () {
        this.loadProcurementProjects()
      },
      methods: {
        viewProjectHistory(project){
            this.selected = project
            this.history_drawer = true
        },
        viewPIN(project){
          this.globalState.loading = true

          PinNotices.show(project.pin_notice.id)
          .then(result => {
            if (result.data.pin_notice) {
              this.pin_notice = result.data.pin_notice
              this.pin_notice.name = project.name
              this.showForm = true
            }
            else{
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.globalState.loading = false
          })
        },
        addProject(){
            this.selected = { id: null }
            this.add_modal = true
        },
        manageProject(project){
            this.selected = project
            this.add_modal = true
        },
        handleClose() {
          this.add_modal = false
          this.dialogVisible = false
        },
        afterSave() {
          this.loadProcurementProjects()
          this.add_modal = false
        },
        search() {
          this.meta.page = 1
          this.loadProcurementProjects()
        },
        remove(bvModalEvt) {
          bvModalEvt.preventDefault()

          this.globalState.loading = true
          ProcurementProjects.delete(this.to_remove)
            .then(result => {
              this.$toast.success(result?.data?.message || 'Project has been removed successfully')
              this.loadProcurementProjects()
              
              this.handleClose()
            })
            .catch(error => {
                this.$toast.error('An error occured while deleting project')
                console.error('error:', error.response.data)

            }).finally(() => {
              this.globalState.loading = false
            })
        },
        showRemove(type) {
          this.to_remove = Object.assign({}, type)
          this.dialogVisible = true
        },
        loadProcurementProjects() {
          this.globalState.loading = true
          this.handleClose()
          this.procurement_projects = []
          ProcurementProjects.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value,
              }
            })
            .then(result => {
              if (result.data.procurement_projects) this.procurement_projects = result.data.procurement_projects
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      },
    }
    </script>
    