
<template>
   <div style="text-align: center">
    <el-transfer
        v-model="transfer"
        :filter-placeholder="filter_placeholder"
        :data="data"
        filterable
        :filter-method="filterMethod"
        @change="handleChange"
        :titles="[left_title, right_title]"
        :button-texts="['Remove', 'Select']"
    >
    <template #right-footer>
        <el-button @click="handleClick" :disabled="selected_data.length == 0" class="transfer-footer" type="success">{{ right_button_text }}</el-button>
    </template></el-transfer>
   </div>
    
</template>

  <script>
  export default {
    props: ['transfer_data', 'filter_placeholder', 'left_title', 'right_title', 'right_button_text'],
    data() {
        const generateData = _ => {
            const data = [];
            this.transfer_data.forEach(function(row){
                data.push({
                    key: row.id,
                    label: row.name
                });
            });
            return data;
        }
        return {
            selected_data: [],
            data: generateData(),
            transfer: [0]
        }
    },
    methods: {
        filterMethod(query, item){
            return item.label.toLowerCase().includes(query.toLowerCase())
        },
        handleChange(value, direction, movedKeys) {
            this.selected_data = value
            this.$emit("update_value", value)
        },
        handleClick(){
            this.$emit("handle_click", true)
        }
    }
  }
  </script>

<style>
.el-transfer-panel{
    width: 35% !important;
}

.el-transfer-panel__item, .el-transfer-panel__header{
    margin-left: 0px !important;
}
.transfer-footer{
    float: right !important;
    margin:3px !important
}
</style>