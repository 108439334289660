<template>
    <div class="card my-3">
          <div class="card-header"><h5>{{ selectedName(selected) }}</h5></div>
          <div class="card-body">
            <form @submit.prevent="onSubmit" @reset="onCancel">
              <div class="form-group">
                <label label-for="input-name" >Card Type</label>
                <input type="text" id="input-name" v-model="selected.name" :class="{ 'is-invalid': v$.selected.name.$errors.length, 'form-control form-control-sm': true }">

                <div class="input-errors" v-for="error of v$.selected.name.$errors" :key="error.$uid">
                  <div class="error-text">Card Type {{ error.$message }}</div>
                </div>
              </div>

              <div class="form-group">
                <label label-for="input-name" >Volume</label>
                <input id="input-twelve_months_volume_sales" v-model="selected.twelve_months_volume_sales" 
                type="number" step=".0000000000000001" :class="{ 'is-invalid': v$.selected.twelve_months_volume_sales.$errors.length, 'form-control form-control-sm': true }">

                <div class="input-errors" v-for="error of v$.selected.twelve_months_volume_sales.$errors" :key="error.$uid">
                  <div class="error-text">Volume {{ error.$message }}</div>
                </div>
              </div>

              <div class="form-group">
                <label label-for="input-name" >Existing Rate</label>
                <input id="input-existing_rates" v-model="selected.existing_rates" 
                type="number" step=".0000000000000001" :class="{ 'is-invalid': v$.selected.existing_rates.$errors.length, 'form-control form-control-sm': true }">

                <div class="input-errors" v-for="error of v$.selected.existing_rates.$errors" :key="error.$uid">
                  <div class="error-text">Existing Rate {{ error.$message }}</div>
                </div>
              </div>

              <div class="form-group">
                <label label-for="input-name" >Proposed Rate</label>
                <input id="input-proposed_rates" v-model="selected.proposed_rates" 
                type="number" step=".0000000000000001" 
                :class="{ 'is-invalid': v$.selected.proposed_rates.$errors.length, 'form-control form-control-sm': true }">

                <div class="input-errors" v-for="error of v$.selected.proposed_rates.$errors" :key="error.$uid">
                  <div class="error-text">Proposed Rate {{ error.$message }}</div>
                </div>
              </div>
              
              <div class="form-check">
                <label 
                label="Active" label-for="input-is_active"
                >Active</label>
                <input class="form-check-input form-check-input-sm" id="input-is_active"
                    v-model="selected.is_active"
                    type="checkbox"
                    name="checkbox-1"
                    :checked="1"
                    :un-checked="0"
                  >
              </div>
              <button type="submit" class="btn bg-gradient-success btn-sm">Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
        </div>
</template>
    
<script>

import { CardTypeRate } from '@/api_services/card_type_rate'

import { useVuelidate } from '@vuelidate/core'
import {  required } from '@vuelidate/validators'

export default {
    setup () {
    return { v$: useVuelidate() }
  },
    props: {
        card_type_rate_data: {
            type: Object,
            required: false
        },
        original: {
            type: String,
            required: false
        },
    },
    data() {
        return{
            selected: {
                name: null,
                twelve_months_volume_sales: null,
                existing_rates: null,
                proposed_rates: null
            },
        }
    },
    validations() {
        return {
        selected: {
            name: {
            required,
            $autoDirty: true
            },
            twelve_months_volume_sales: {
            required,
            $autoDirty: true
            },
            existing_rates: {
            required,
            $autoDirty: true
            },
            proposed_rates: {
            required,
            $autoDirty: true
            }
        },
        }
    },
    watch: {
        card_type_rate_data: function () {
            this.selected = Object.assign({}, this.card_type_rate_data)
        },
    },
    created: function () {
        if (this.card_type_rate_data) this.selected = Object.assign({}, this.card_type_rate_data)
    },
    methods: {
        async onSubmit(evt) {
            evt.preventDefault()

            const result = await this.v$.$validate()
            if (!result) return

            var action = this.selected.id ? CardTypeRate.update(this.selected.id, this.selected) : CardTypeRate.create(this.selected)
            action.then(result => {
            this.$toast.success('Saved successfully')
            this.$emit('close_form', "save")
            })
            .catch(error => {
            this.$toast.error('An error occured while saving a card type')
            console.error('error:', error.response.data)
            })
        },
        selectedName(selected) {
            return selected.id ? 'Edit ' + selected.name : 'New'
        },
        reset() {
            this.selected = null
        },
        onCancel(evt) {
            evt.preventDefault()
            this.$emit('close_form', "close")
        },
    }
}
</script>
    
<style></style>
    