<template>
  <div class="card">
    <div class="card-body">
      <div><button type="submit" class="ms-2 btn btn-success rounded-0" @click="meetingModal = true" v-if="isOriginator">Set a meeting</button></div>
      <div class="table-responsive">
        <table class="table table-hover table-fluid align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organiser
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organisation
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Meeting Title
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Start Date  
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                End Date
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Actions
              </th>
            </tr>
            <tr v-for="(meeting, index) in meetings" :key="index">
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.organizer.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.company.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.title }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.start_datetime}}  
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.end_datetime }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                <el-button @click="openJaasModal(meeting)" size="small" type="success">Join meeting</el-button>
                <el-button @click="openMeetingModal(meeting)"  size="small" type="primary">Meeting Details</el-button>
                <el-button v-if="isOrganizer(meeting)" @click="deleteMeeting(meeting.id)"  size="small" type="danger">Cancel/Remove Meeting</el-button>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    
    </div>
  </div>

  <el-dialog
      v-model="jaasModal" 
      title="Meet"
      width="67%"
      @close="closeJaasModal()"
    >
    <div>
      <jaas-component :key="selected_meeting ? selected_meeting.id : 'default'" :meeting="selected_meeting"></jaas-component>
    </div>
  </el-dialog>

    <el-dialog
      v-model="meetingModal" 
      :title = "selected_meeting ? 'Meeting Details' : 'Schedule A Meeting'"
      width="67%"
      @close="closeMeetingModal()"
    >
      <div>
        <meeting-details :key="selected_meeting ? selected_meeting.id : 'default'" @close_modal="closeMeetingModal()" @update="getMeetings()" :meeting="selected_meeting" :currentUser="savedUser"></meeting-details>
      </div>
    </el-dialog>

</template>

<script>
  import { mapState } from 'vuex'
  import Modal from '@/components/common/Modal'
  import JaasComponent from '@/components/common/Jaas/jaas.vue'
  import MeetingDetails from '@/components/common/Jaas/jaas_meeting_details.vue'
  import { Jaas } from '@/api_services/jaas'

  export default {
    components: {
      Modal,
      JaasComponent,
      MeetingDetails
    },
    props: {

    },
    data() {
      return {
        attachFileForm: null,
        jaasModal: false,
        meetingModal: false,
        meetings: null,
        selected_meeting: null
      }
    },
    computed: {
      ...mapState(['savedUser']),
      isOriginator() {
        return this.currentRole == "originator"
      },
      currentRole() {
        const roleNames = (this.savedUser.role_names || []).map(r => r.toLowerCase())
        if (roleNames.includes('originator')) {
          return 'originator'
        }
        return null
      }
    },
    created () {
      this.getMeetings()
    },
    methods: {
      isOrganizer(meeting) {
        return meeting.organizer_id == this.savedUser.id
      },
      getMeetings() {
        Jaas.get().then(result => {
          this.meetings = [...result.data.invitee_meetings, ...result.data.organizer_meetings]
        })
      },
      openJaasModal(meeting) {
        this.selected_meeting = meeting
        if(this.selected_meeting) this.jaasModal = true;
      },
      closeJaasModal() {
        this.selected_meeting = null;
        this.jaasModal = false
      },
      openMeetingModal(meeting) {
        this.selected_meeting = meeting
        this.meetingModal = true;
      },
      closeMeetingModal() {
        this.selected_meeting = null;
        this.meetingModal = false
      },
      deleteMeeting(id) {
        Jaas.delete(id).then(deleted => {
          this.$toast.success('Selected meeting has been deleted')
          this.getMeetings()
        })
      }
    }
  }

</script>