<template>
  <loading-component :loading="loading" :message="loading_title_message" :content="loading_content_message"></loading-component>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Pin Notices</h5>
            <div class="pb-0 card-header">
              <div class="row">
              <div class="col-md-2">
                  <el-input v-model="search_value" placeholder="Search" class="input-with-select">
                  <template #append>
                      <el-button><i class="fa fa-search"></i></el-button>
                  </template>
                  </el-input>
              </div>
              <div class="col-md-10" style="text-align: right;">
                  <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="loadPinNotices()">
                  <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                  </button>
              </div>
              </div>
              <div class="text-end">
              </div>
          </div>
          <br>
            <div class="table-responsive">
              <table class="table table-fluid align-items-center mb-0 table-hover ">
                <thead>
                  <tr>
                    
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Project
                    </th>
                    <th v-if="$store.state.isUserASupplier()" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Buyer
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        PIN Status
                      </th>
                      <th v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Project Status
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Deadline
                      </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!pin_notices.length">
                    <td v-if="loading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="pin_notice in pin_notices" :key="pin_notice.id">
                    
                      <td>{{ pin_notice.name }}</td>
                      <td v-if="$store.state.isUserASupplier()">{{ pin_notice.user.name }}</td>
                      <td>{{ $filters.dateFormatFilter(pin_notice.created_at) }}</td>
                      <td>
                            <el-tag
                              type="warning"
                              v-if="pin_notice.pin_status == 'Pending'"
                              effect="light"
                              >{{ pin_notice.pin_status }}</el-tag
                            >
                            <el-tag
                              type="success"
                              v-if="pin_notice.pin_status == 'Active'"
                              effect="dark"
                              >{{ pin_notice.pin_status }}</el-tag
                            >
                            <el-tag
                              type="info"
                              v-if="pin_notice.pin_status == 'Archived'"
                              effect="dark"
                              >{{ pin_notice.pin_status }}</el-tag
                            >
                      </td>
                      <td v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()">
                          <el-tag
                              v-tooltip="pin_notice.project_status_label"
                              :type="pin_notice.project_status_tag.color"
                              :effect="pin_notice.project_status_tag.type"
                              >{{ pin_notice.project_status_label }}</el-tag
                          >
                      </td>
                      <td>{{ pin_notice.deadline ? ($filters.dateFormatFilter(pin_notice.deadline) +  (pin_notice.is_deadline_passed == 1 ? " (Passed)" : "") ) : "No deadline yet" }}</td>
                      <td >
                        <el-button-group>
                          <el-tooltip :enterable="false" placement="top-start">
                            <template #content>
                              <span>Edit Details</span>
                            </template>

                            <el-button
                              v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()"
                              type="primary" size="small" @click="openPinForm(pin_notice)" >
                                <i class="fa fa-edit"></i></el-button>
                          </el-tooltip>
                        
                          <el-tooltip :enterable="false" placement="top-start">
                            <template #content>
                              <span>View PIN</span>
                            </template>

                            <el-button
                              :disabled=disable_state
                                v-if="pin_notice.status == PinNoticeStatuses.ACTIVE || pin_notice.status == PinNoticeStatuses.ARCHIVED"
                              type="success" size="small" @click="viewPin(pin_notice)" >
                                <i v-if="disable_count == 0" class="fa fa-eye"></i>{{ disable_count > 0 ? disable_count : "" }}</el-button>
                          </el-tooltip>

                          <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>View History</span>
                              </template>

                              <el-button
                              v-if="$store.state.isUserABuyer() || $store.state.isUserAnOriginator()"
                                type="warning" size="small" @click="viewProjectHistory(pin_notice)" >
                                  <i class="fa fa-history"></i></el-button>
                            </el-tooltip>

                            <el-button
                            v-if="pin_notice.project_status == ProcurementProjectStatuses.CODE_PENDING && ($store.state.isUserABuyer() || $store.state.isUserAnOriginator())"
                              type="primary" size="small" @click="sendPublishRequest(pin_notice)" >
                                <i class="fa fa-upload"></i>&nbsp;Publish PIN Notice</el-button>
                        
                          <el-button
                                v-if="pin_notice.project_status == ProcurementProjectStatuses.CODE_DOC_FINALIZE && $store.state.isUserAnOriginator()"
                              type="danger" size="small" @click="initiatePin(pin_notice)" >
                                <i class="fa fa-upload"></i>&nbsp;Initiate ITT</el-button>

                          
                          
                        </el-button-group>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="loadPinNotices()" />
          </div>
        </div>
      </div>
    </div>
  
  </div>

  <el-dialog id="pin-modal" v-model="pin_modal"  width="70%">
    <PinNoticeForm @close="afterSave" @cancel="pin_modal = false" :key="'pin_form_' + pin_notice.id" :pin_notice="pin_notice"></PinNoticeForm>
  </el-dialog>

  <el-drawer
      v-model="showForm"
      :show-close="true"
      size="80%"
      direction="rtl" 
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div v-if="selected" class="container">
        <PinNoticeView :view_itt="1" :selected_framework="selected" @close="loadPinNotices()"></PinNoticeView>
      </div>
    </el-drawer>

    <el-drawer
      v-model="history_drawer"
      :show-close="true"
      size="40%"
      direction="rtl" 
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div v-if="selected" class="container">
        <ProcurementProjectHistory :procurement_project_id="selected.procurement_project_id" :name="selected.name" ></ProcurementProjectHistory>
      </div>
    </el-drawer>

  </template>
  
  <script>
  import { PinNotices } from '@/api_services/pin_notices'
  import { ProcurementProjects } from '@/api_services/procurement_projects'
  import PinNoticeForm from './PinNoticeForm'
  import { mapState } from 'vuex'
  import PinNoticeView from './PinNoticeView'
  import Swal from 'sweetalert2';
  import { IttFramework } from '@/api_services/itt_framework'
  import LoadingComponent from '@/components/common/LoadingComponent.vue'
  import ProcurementProjectHistory from '../procurement_projects/ProcurementProjectHistory.vue'
  import $ from 'jquery'

  export default {
    computed: {
      ...mapState(['ProcurementProjectStatuses', 'PinNoticeStatuses'])
    },
    components: {
      PinNoticeForm, PinNoticeView, LoadingComponent, ProcurementProjectHistory
    },
    data() {
      return {
        form: null,
        history_drawer: false,
        loading_title_message: null,
        loading_content_message: null,
        loading: false,
        itt_form: {
          name: null,
          main_description: null,
          common_description: null,
          my_files_description: null,
          qa_description: null,
          my_questions_description: null,
          spec_description: null,
          status: 1,
          pin_notice_id: null,
          procurement_project_id: null,
          if_pin_notice: 1
        },
        pin_modal: false,
        selected: null,
        add_modal: false,
        dialogVisible: false,
        pin_notices: [],
        pin_notice: null,
        to_remove: null,
        meta: {
          page: 1,
          total: 0,
          pages: 1
        },
        showForm: false,
        loading: false,
        search_value: null,
        disable_count: 0,
        disable_state: false
      }
    },
    watch: {
      search_value: function () {
          if (!this.search_value || this.search_value.length > 2) this.loadPinNotices()
      }
    },
    created: function () {
      this.loadPinNotices()
    },
    methods: {
      sendPublishRequest(pin_notice){
        if(pin_notice.procurement_project){
          Swal.fire({
            title: 'Submit PIN Publish Request?',
            text: 'Do you want to proceed?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true

              this.form = pin_notice.procurement_project
              this.form.status = this.ProcurementProjectStatuses.CODE_SUBMITTED

              ProcurementProjects.update(this.form.id, this.form)
              .then(result => {
                this.$toast.success('Pin request submitted')
                this.loadPinNotices()
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
              
            }
          });
        }
      },
      viewProjectHistory(pin_notice){
        this.selected = Object.assign({}, pin_notice)
        this.history_drawer = true
      },
      initiatePin(pin_notice){
        Swal.fire({
          title: 'Are you sure?',
          text: 'Once ITT is initiated, this PIN will be archive. This process is irreversible. Do you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue',
          cancelButtonText: 'Return to PIN',
        }).then((result) => {
          if (result.isConfirmed) {

            this.loading_title_message = "Initiating ITT"
            this.loading_content_message = "Please wait......."
            this.globalState.loading = true

            this.itt_form.name = pin_notice.name
            this.itt_form.main_description = pin_notice.main_description
            this.itt_form.common_description = pin_notice.common_description
            this.itt_form.my_files_description = pin_notice.my_files_description
            this.itt_form.qa_description = pin_notice.qa_description
            this.itt_form.my_questions_description = pin_notice.my_questions_description
            this.itt_form.spec_description = pin_notice.spec_description
            this.itt_form.pin_notice_id = pin_notice.id
            this.itt_form.procurement_project_id = pin_notice.procurement_project_id

            IttFramework.create(this.itt_form)
            .then(result => {
              this.$toast.success('New ITT Framework has been created and currently pending')
              this.$router.push({ path: '/main/itt-frameworks' })
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
            
          }
        });
      },
      buttonCooldown(){
        this.disable_count = this.$button_cooldown
        this.disable_state = true
        let self = this

        var interval = setInterval(function(){
          self.disable_count--

          if(self.disable_count == 0){
            self.disable_count = 0
            self.disable_state = false
            clearInterval(interval)
          }
          
        }, 1000)
      },
      viewPin(pin_notice) {

        if(this.$store.state.isUserABuyer() || this.$store.state.isUserAnOriginator()){
          this.globalState.loading = true
          
          PinNotices.show(pin_notice.id)
          .then(result => {
            if (result.data.pin_notice) {
              this.selected = Object.assign({}, result.data.pin_notice)
              this.showForm = true

              this.buttonCooldown()
            }
            else{
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.globalState.loading = false
          })
        }

        if(this.$store.state.isUserASupplier()){
          this.$router.push({ path: '/main/pin_notices/' + pin_notice.id })
        }
      },
      openPinForm(pin_notice){
          PinNotices.show(pin_notice.id)
          .then(result => {
            if (result.data.pin_notice) {
              this.pin_notice = result.data.pin_notice
              this.pin_notice.name = pin_notice.name
              this.pin_modal = true
            }
            else{
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
        },
      handleClose() {
        this.add_modal = false
      },
      afterSave() {
        this.loadPinNotices()
        this.pin_modal = false
      },
      search() {
        this.meta.page = 1
        this.loadPinNotices()
      },
      remove(bvModalEvt) {
        bvModalEvt.preventDefault()

        this.globalState.loading = true
        /*PinNotices.delete(this.to_remove)
          .then(result => {
            this.$toast.success(result?.data?.message || 'Project has been removed successfully')
            this.loadPinNotices()

            this.handleClose()
          })
          .catch(error => {
              this.$toast.error('An error occured while deleting project')
              console.error('error:', error.response.data)

          }).finally(() => {
              this.globalState.loading = false
          })*/
      },
      showRemove(type) {
        this.to_remove = Object.assign({}, type)
        this.dialogVisible = true
      },
      loadPinNotices() {
        this.globalState.loading = true
        this.handleClose()
        this.pin_notices = []
        PinNotices.get({
            params: {
              page: this.meta.page,
              search_value: this.search_value,
            }
          })
          .then(result => {
            if (result.data.pin_notices) this.pin_notices = result.data.pin_notices
            this.meta = result.data.meta
          })
          .catch(error => {
  
          })
          .finally(() => {
            this.globalState.loading = false
          })
      }
    },
  }
  </script>
  