import store from '@/store/index'
import CouncilJson from '@/councils.json'
import { Council } from '@/api_services/council'

let colorList = [
  '#ffadad',
  '#9bf6ff',
  '#caffbf',
  '#fdffb6',
  '#DA70D6',
  '#F08080',
  '#FF8C00',
  '#FAFAD2',
  '#98FB98',
  '#AFEEEE'
]

export default {
  councils: function() {
    // let collection = CouncilJson.councils
    // collection.unshift({ council: '' })

    Council.get({ params: { term: this.term, council: true } })
      .then(result => {
        let cs = result.data.councils ? result.data.councils : []
        return [...new Map(cs.map(item => [item['council'], item])).values()]
      })
      .catch(error => {
        console.error('error:', error.response.data)
        return []
      })
  },
  canEdit(module) {
    if (!store.state.savedUser) return false
    if (!store.state.savedUser.permissions) return false
    let edit = false

    store.state.savedUser.permissions.forEach(permission => {
      if (
        module.toUpperCase() == permission.module.toUpperCase() &&
        permission.action.toUpperCase() == 'UPDATE'
      ) {
        edit = true
      }
    })

    return edit
  },
  isVisible(module, action) {
    if (!store.state.savedUser) return false
    if (!store.state.savedUser.permissions) return false
    let exist = false

    store.state.savedUser.permissions.forEach(permission => {
      if (module == permission.module && action == permission.action) {
        exist = true
      }
    })
    //console.log("bleee hahahah")
    return exist
  },
  toCurrency(amount, decimals = 4) {
    if (!amount) return ''
    let prefix = ''
    if (amount < 0) prefix += '-'
    return (
      prefix +
      '£' +
      amount.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    )
  },
  companyFullAddress(company) {
    var address = company.address1
    if (company.address2) address += ', ' + company.address2
    if (company.council_name) address += ', ' + company.council_name
    if (company.city) address += ', ' + company.city
    if (company.state) address += ', ' + company.state
    if (company.zip) address += ', ' + company.zip

    return address
  },
  getHexColor(index) {
    let color = null

    if (typeof colorList[index] === 'undefined') {
      color = this.getRandomHex(colorList)
      colorList.push(color)
    } else {
      color = colorList[index]
    }

    return color
  },
  getRandomHex(existingColors = []) {
    let len = 6
    const hex = '0123456789ABCDEF'
    let output = '#'
    for (let i = 0; i < len; ++i) {
      output += hex.charAt(Math.floor(Math.random() * hex.length))
    }

    if (existingColors.includes(output))
      output = this.getRandomHex(existingColors)

    return output
  },
  getCompany() {
    if (!store.state.savedUser) return null
    if (!store.state.savedUser.company) return null
    return store.state.savedUser.company
  },
  isAllowed(name, action) {
    var response = false
    var user = store.state.savedUser
    if (user) {
      if (user.permissions) {
        user.permissions.forEach(function(permission) {
          if (
            permission.action.toLowerCase() === action.toLowerCase() &&
            permission.module.toLowerCase() === name.toLowerCase()
          ) {
            response = true
          }
        })
      }
    }
    return response
  },
  isViewable(name) {
    var response = false
    var user = store.state.savedUser
    if (user) {
      if (user.permissions) {
        user.permissions.forEach(function(permission) {
          if (
            permission.action === 'VIEW' &&
            permission.module.toLowerCase() === name.toLowerCase()
          ) {
            response = true
          }
        })
      }
    }
    return response
  },
  getDeviceType() {
    let deviceType = null
      const platform = navigator.platform.toLowerCase()
      if (
        /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(
          platform
        )
      ) {
        deviceType = 'mobile'
      } else if (/mac|win|linux/i.test(platform)) {
        deviceType = 'desktop'
      } else if (/tablet|ipad/i.test(platform)) {
        deviceType = 'tablet'
      } else {
        deviceType = 'unknown'
      }

      return deviceType
  }
}
