<template>
    <div>
        <div v-if="emails.length > 0" class="form-control mb-2">
            <el-tag
                v-for="(email, i) in emails"
                class="mb-1"
                :key="i"
                closable
                type="primary"
                style="margin-right: 10px"
                @close="handleClose(email)"
            >
                {{ email }}
            </el-tag>
        </div>
      
  
      <el-input
        class="input-new-tag mt-2"
        v-model="email"
        v-if="inputVisible"
        ref="saveTagInput"
        placeholder="Enter email here..."
        v-on:keydown.enter.prevent='handleInputConfirm'
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button type="primary" v-else class="button-new-tag" size="small" @click="showInput"
        >+ New Email</el-button
      >
    </div>
  </template>
  
  <script>
  export default {
    props:{
        email_data: {type: String, required: false}, 
    },
    data() {
      return {
        error: null,
        emails: [],
        email: null,
        inputVisible: false
      }
    },
    watch: {
      emails: {
        handler(newValue, oldValue) {
          this.$emit('update', newValue.join(','))
        },
        deep: true
      },
      email_data() {
        if(this.email_data){
            this.email_data.split(",")
            this.emails = this.email_data.split(",")
        }
        else    
            this.emails = []
      }
    },
    created: function() {
      if(this.email_data){
        this.emails = this.email_data.split(",")
      }
    },
    methods: {
      addEmail() {
        this.emails.push(this.email)
      },
      handleClose(tag) {
        this.emails.splice(this.emails.indexOf(tag), 1)
      },
      showInput() {
        this.inputVisible = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },
      handleInputConfirm(evt) {
        if (!this.email){
            this.inputVisible = false
            return false
        } 

        this.error = null
        if (evt) evt.preventDefault()
        let email = this.email
        let isValid = this.validatedEmail(email)
        if (!isValid) {
          this.email = ''
          this.error = "Invalid Email"
          
          this.$toast.error(this.error)
          this.inputVisible = false

          let self = this
          setTimeout(function () {
            self.error = null
          }, 3000);
          return false;
        } 
        if (email) {
          this.emails.push(email)
        }
        this.inputVisible = false
        this.email = ''
        this.error = null
  
        
      },
      validatedEmail(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true
        }
        return false
      }
    }
  }
  </script>
  