<style>
  .correspondent_notification .el-badge__content{
    animation: 1.7s ease 0s infinite beat !important;
    right:-13px !important;
    top:-6px !important
  }
  @keyframes beat {
    0%, 50%, 100% { transform: scale(1.2, 1.2); }
    30%, 80% { transform: scale(0.92, 0.95); }
  }
</style>
<template>
  <div class="home mt-3">
    <div class="row" v-if="isViewable('BUYER') && !is_website_bcp">
      <div class="col-md-12">
        <div class="card p-3 text-lg">
          <p>Welcome to the CPRAS Procurement Platform, carefully designed to cater to your procurement needs and help
            you find the most ideal products or services for your organisation.</p>
          <p>
            At CPRAS, we understand the importance of efficient and effective procurement. That's why our product search
            is equipped with powerful features that allow you to tailor every element of your search requirements to
            discover the most relevant offerings for your specific requirements.
          </p>
          <p>
            Whether you are searching for essential supplies, innovative
            solutions, or cost-effective services, our Platform is here to
            assist you.
          </p>
          <p>
            If you have any questions, please get in touch with our dedicated support team via the link below. We are
            here to assist you through every stage of the procurement process.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'procurement_project,update'" v-if="!is_website_bcp">
        <div class="card bg-cover bg-info dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Procurement Project</h4>
            <router-link :to="{ name: 'procurement_projects' }" class="stretched-link">
              <i class="fa-solid fa-list" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'comparison_tool,view'" v-if="!is_website_bcp">
        <div class="card bg-cover h-100 bg-gradient-info dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Comparison Tool</h4>
            <router-link :to="{ name: 'ComparisonTool' }" class="stretched-link">
              <i class="fa-solid fa-chart-simple" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'cost_data_report,view'" v-if="!is_website_bcp">
        <div class="card bg-cover h-100 bg-gradient-secondary dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Monthly Reporting</h4>
            <router-link :to="{ name: 'monthly_reporting' }" class="stretched-link">
              <i class="fa-solid fa-chart-line" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>


      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'products,update'" v-if="!is_website_bcp">
        <div class="card bg-cover h-100 bg-gradient-warning dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Manage Products</h4>
            <router-link :to="{ name: 'products_list' }" class="stretched-link">
              <i class="fa-solid fa-list" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'supplier,view'" v-if="!is_website_bcp">
        <div class="card bg-cover h-100 bg-primary dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">My Further Competition</h4>
            <router-link :to="{ name: 'product_search_results' }" class="stretched-link">
              <i class="fa-solid fa-list" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'supplier,view'">
        <div class="card bg-cover h-100 bg-info dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">My Call-Off Documents</h4>
            <router-link :to="{ name: 'SupplierPendingCallOffsComponent' }" class="stretched-link">
              <i class="fa-solid fa-file" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'search_products,view'">
        <div class="card bg-cover h-100 bg-gradient-primary dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Product Search</h4>
            <router-link :to="{ name: 'SearchSolution' }" class="stretched-link">
              <i class="fa-solid fa-magnifying-glass" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'buyer,view'">
        <div class="card bg-cover h-100 bg-gradient-warning dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">My procurement progress</h4>
            <router-link :to="{ name: 'product_search_queries_list' }" class="stretched-link">
              <i class="fa-solid fas fa-chart-line" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'buyer,view'">
        <div class="card bg-cover h-100 bg-gradient-success dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">My Saved Searches</h4>
            <router-link :to="{ name: 'saved_searches' }" class="stretched-link">
              <i class="fa-solid fas fa-search" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'buyer,view'">
        <div class="card bg-cover h-100 bg-gradient-success dhbutton">
          <a class="p-4 h5" style="position: absolute; top: 0px; right: 0px;">
            <el-badge :value="correspondent_count" class="correspondent_notification"
              :hidden="correspondent_count == 0">
            </el-badge>
          </a>
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Correspondence</h4>
            <router-link :to="{ name: 'ProductSearchMessageList' }" class="stretched-link">
              <i class="fa-solid fa-envelope" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'buyer,view'">
        <div class="card bg-cover h-100 bg-gradient-info dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">My Call-Off Documents</h4>
            <router-link :to="{ name: 'BuyerCallOffDocuments' }" class="stretched-link">
              <i class="fa-solid fa-file" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'support_ticket,view'">
        <div class="card bg-cover h-100 bg-gradient-success dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Contact Platform Support Team</h4>
            <router-link :to="{ name: 'SupportTicketHome' }" class="stretched-link">
              <i class="fa-solid fa-circle-info" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'supplier_hub_all,view'" v-if="!is_website_bcp">
        <div class="card bg-cover h-100 bg-gradient-info dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">ITT Archives</h4>
            <router-link :to="{ name: 'SuppliersHubDashboard' }" class="stretched-link">
              <i class="fa-solid fa-gauge" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 mt-3 mb-3" v-allowed="'help_resources,view'">
        <div class="card bg-cover h-100 bg-gradient-warning dhbutton">
          <div class="card-body z-index-2 py-7">
            <h4 style="color:#ffffff">Video Resources</h4>
            <router-link :to="{ name: 'UserHelpResourcesList' }" class="stretched-link">
              <i class="fa-solid fa-video-camera" style="font-size: 6rem; color:#ffffff"></i>
            </router-link>
          </div>
          <hr class="text-white p-0 m-0">
          <div class="container py-1">
            <a href="#" class="float-left">
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
              <i class="fa-solid fa-circle text-white px-1"></i>
            </a>
            <a class="text-white float-end" href="#">Click to open <i class="fa-solid fa-arrow-right-long"></i></a>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  watch: {
    '$store.state.new_message_count':{
      handler(value){
        this.correspondent_count = value
      },
      deep:true
    }
  },
  name: 'Home',
  data() {
    return {
      correspondent_count: this.$store.state.new_message_count,
      user: null,
      files: [],
      is_website_bcp: parseInt(process.env.VUE_APP_IS_BCP)
    }
  },

  created: function() {
    this.user = this.$store.state.savedUser
    this.$store.commit('newNotifications', true)
  },

  methods: {
    isViewable(permission) {
      return this.$store.state.isViewable(permission)
    }
  }
}
</script>

<style>

@media (max-width:1570px) and (min-width: 992px) {
  .dhbutton {
    height: 400px !important;
  }
}

@media (max-width:860px) and (min-width: 768px) {
  .dhbutton {
    height: 400px !important;
  }
}

</style>
