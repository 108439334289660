<template>
  <div>
    <div v-if="$store.state.isViewable('cost_data_report')">
      <h2 class="text-white">Merchant Services Reports</h2>
      <div class="col-6 mb-3">
        <nav-pill v-model="pill_option" id="header-status-nav-pills">
          <li class="nav-item" v-if="$helpers.isVisible('HISTORICAL_DATA','VIEW')">
            <a @click="setTab(2)" class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="#" role="tab" :value="2">
              COST DETAILS
            </a>
          </li>
          <li class="nav-item" v-if="$helpers.isVisible('COST_DATA','VIEW')">
            <a @click="setTab(0)" class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="#" role="tab" :value="0">
              CARD TRANSACTIONS
            </a>
          </li>
          <li class="nav-item" v-if="$helpers.isVisible('COST_DATA','VIEW')">
            <a @click="setTab(1)" class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="#" role="tab" :value="1">
              REPORTS
            </a>
          </li>
          <li class="nav-item" v-if="$helpers.isVisible('COST_DATA','VIEW')">
            <a @click="setTab(3)" class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="#" role="tab" :value="3">
              EXPORTS
            </a>
          </li>
          
        </nav-pill>
      </div>

      <div class="card my-3">
        <div class="card-body">
          <cost-data-component v-if="pill_option == 0"></cost-data-component>
          <CostDataReportsComponent v-if="pill_option == 1"></CostDataReportsComponent>
          <monthly-collections-component v-if="pill_option == 2"></monthly-collections-component>
          <cost-data-exports-component v-if="pill_option == 3"></cost-data-exports-component>
        </div>
      </div>
    </div>
    <not-allowed v-else></not-allowed>
  </div>
</template>

<script>
// import CardTransactionsComponent from './card_transactions_component.vue'
import CostDataComponent from './cost_data_component.vue'
import CostDataReportsComponent from './cost_data_reports_component.vue'
import MonthlyCollectionsComponent from './monthly_collections_component.vue'
import NavPill from '@/components/common/NavPill'
import CostDataExportsComponent from '../cost_data_exports/CostDataExportsComponent.vue'


export default {
  components: { CostDataComponent, CostDataReportsComponent, MonthlyCollectionsComponent,NavPill, CostDataExportsComponent },
  data() {
    return {
      pill_option: 2
    }
  },
  created: function() {

  },
  methods: {
    setTab(tabindex){
      this.pill_option = tabindex
    }
  }
}
</script>

<style scoped>
  #header-status-nav-pills li a.active {
    font-weight: 600;
  }
</style>
