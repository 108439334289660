<template>
    <h4>Procurement Project History</h4>
    <p>{{ selected.name }}</p>
    <div class="card">
        <div class="card-body">
            <el-timeline v-if="histories.length > 0" >
                <el-timeline-item
                placement="top"
                v-for="(history, index) in histories"
                :key="index"
                :timestamp="$filters.datetimeFormatFilter(history.created_at)"
                :hollow="true"
                :type=" index == 0 ? 'primary' : '' "
                >
                
                <!--<h6 class="mb-0">{{ history.column_name.replace('_', ' ').replace("s", "") }}</h6>-->
                <p class="p-0">
                    {{ history.history_text }}
                </p>

                </el-timeline-item>
            </el-timeline>

            <h5 v-else>No history found!</h5>
        </div>
    </div>
    
  </template>
  
  <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects'
    
    export default {
      props: {
        procurement_project_id: Number,
        name: String
      },
      data() {
        return {
            selected: {
                id: null,
                name: null
            },
            histories: [],
        }
      },
      created: function () {
        this.selected.id = this.procurement_project_id
        this.selected.name = this.name
        this.loadHistory(this.selected)

      },
      watch: {
        procurement_project_id(procurement_project_id) {
          this.selected = procurement_project_id
        },
        name(name) {
          this.selected.name = name
        }
      },
      computed: {
        
      },
      methods: {
        loadHistory(project){
            ProcurementProjects.load_history(project.id)
            .then(result => {
                if (result.data.procurement_project_history) {
                    this.histories = result.data.procurement_project_history

                    console.log(this.histories)
                }
                else{
                    this.$toast.error('No data found')
                }
            })
            .catch(error => {
                this.$toast.error('An error occured while fetching request')
                console.error('error:', error.response.data)
            })
        }
      }
    }
  </script>
  