<template>
  <div style="padding: 10px">
    <form @submit.prevent="onSubmit()">
        <p class="text-wrap">{{ question.question }}</p>
      <!-- <div class="form-group">
        <label for="exampleFormControlSelect1">Type</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="form.type"
        >
          <option
            v-for="(type, i) in $store.state.qa_types"
            :key="i"
            :value="i"
            >{{ type }}</option
          >
        </select>
      </div> -->
      <argon-input
        type="textarea"
        :value="form.answer"
        field="Answer"
        :required="true"
        :label="true"
        @update="updateField('answer', $event)"
      ></argon-input>

       <div style="padding: 10px">
        <el-radio v-model="form.type" :label="0">Private</el-radio>
        <el-radio v-model="form.type" :label="1">Public</el-radio>
      </div>

      <button type="submit" class="btn btn-info" :disabled="!isValid">
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import ArgonInput from '@/components/common/Input/ArgonInput.vue'
import { Question } from '@/api_services/question'

export default {
  components: { ArgonInput },
  props: ['question'],
  data() {
    return {
      validFields: {
        answer: false
      },
      form: {
        answer: null,
        type: 0
      }
    }
  },
  watch: {
    selected: function() {
      if (this.question) this.form.question_id = this.question.id
    }
  },
  created: function() {
    if (this.question) this.form.question_id = this.question.id
  },
  methods: {
    updateField(name, newItem) {
      this.validFields[name] = newItem ? true : false
      this.form[name] = newItem
    },
    onSubmit() {
      this.loading = true
      Question.answer(this.question.id, this.form)
        .then(result => {
          this.$toast.success('Submit successfully')
          this.$emit('update', true)
        })
        .catch(error => {
          // this.$toast.error('An error occured while fetching common files')
          // console.error('error:', error.response.data)
        })
        .finally(res => {
          this.loading = false
        })
    }
  },
  computed: {
    isValid() {
      let valid = true
      for (var i in this.validFields) {
        if (!this.validFields[i]) valid = false
      }

      return valid
    }
  }
}
</script>
