<template>
    <div class="row" v-if="meeting && currentUser.id != meeting.organizer_id">
        <label>Attendance Status</label>
        <div class="col-md-8">
            <el-select :disabled="isInProgress" v-model="attendance_status" placeholder="Select" style="width: 100%;">
            <el-option :label="'Not Responded Yet'" :value="0"></el-option>
            <el-option :label="'Attend Meeting'" :value="1"></el-option>
            <el-option :label="'Decline Meeting'" :value="2"></el-option>
            <el-option :label="'Tentative'" :value="3"></el-option>
            </el-select>
        </div>
        <div class="col-md-4">
            <!-- <button @click="updateAttendanceStatus()" class="ms-2 btn btn-success rounded-0">Update Attendance</button> -->
            <el-button @click="updateAttendanceStatus()" size="medium" type="success">Update Attendance</el-button>
        </div>
    </div>
    <form @submit.prevent="submit">
    <div class="form-group">
        <div class="row">
            <div class="p-3 text-justify text-sm col-sm-12">
                <label>Title</label>
                <div :class="{ 'has-error-field': v.form.title.$invalid }">
                    <el-input :disabled="meeting && currentUser.id != meeting.organizer_id" v-model="form.title" placeholder="Meeting title" ></el-input>
                </div>
                <div v-if="v.form.title.$invalid" class="error-text" >Title is required</div>
            </div>
            <div class="p-3 text-justify text-sm col-sm-6">
                <label>Meeting Date</label>
                <br/>
                <!-- <el-date-picker v-model="form.date"></el-date-picker> -->
                <!-- <input class="form-control" type="text" id="time"/>   -->
                <div :class="{ 'has-error-field': v.form.start_datetime.$invalid }">
                    <Datepicker :disabled="meeting && currentUser.id != meeting.organizer_id" v-model="form.start_datetime" :min-date="getDateNow()" :min-time="getTimeNow()"></Datepicker>
                </div>
                <div v-if="v.form.start_datetime.$invalid" class="error-text" >Start date is required</div>
            </div>
            <div class="p-3 text-justify text-sm col-sm-6">
                <label>End Date</label>
                <br/>
                <!-- <el-date-picker v-model="form.date"></el-date-picker> -->
                <!-- <input class="form-control" type="text" id="time"/>   -->
                <div :class="{ 'has-error-field': v.form.end_datetime.$invalid }">
                    <Datepicker :disabled="meeting && currentUser.id != meeting.organizer_id" v-model="form.end_datetime" :min-date="form.start_datetime" :min-time="getTimeNow()"></Datepicker>
                </div>
                <div v-if="v.form.end_datetime.$invalid" class="error-text" >End date is required</div>
            </div>
      </div>
      <div v-if="meeting && currentUser.id == meeting.organizer_id">
      <label>To</label>
      <div :class="{ 'has-error-field': v.form.recipients.$invalid }">
        <el-select v-model="form.recipients" multiple placeholder="Select" style="width: 100%;">
          <el-option v-for="(item, i) in options" :key="i" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div v-if="v.form.recipients.$invalid" class="error-text">Recipient is required</div>
    </div>
    </div>
    <div class="form-group">
      <label>Description</label>
        <el-input :disabled="meeting && currentUser.id != meeting.organizer_id" type="textarea" name="body" v-model="form.description" placeholder="Message" :rows="5"></el-input>
    </div>
    <div class="row" v-if="meeting && currentUser.id == meeting.organizer_id">
        <div class="col-md-12">
            <table class="table table-hover table-fluid align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Name
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organisation
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Attendance Status
              </th>
            </tr>
            <tr v-for="(invitee, index) in meeting.invitees" :key="index">
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ invitee.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ invitee.company.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ attendanceStatusString(invitee.pivot.attendance_status) }}
              </td>
            </tr>
          </thead>
        </table>
        </div>
    </div>

    <div class="row" v-if="selected_files.length">
        <div class="col-md-12">
            <label>
                File Attachments
            </label>
            <ul v-for="file in selected_files" :key="file.id">
                <li><a
                class="link-primary text-sm"
                :href="file.file_path"
                target="_blank"
                >{{file.file_name}}
                <i class="fa-solid fa-file-lines"></i>
                </a></li>
                </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12" v-if="!meeting || (meeting && currentUser.id == meeting.organizer_id)">
            <br/>
            <label>Attachments</label>
            <argon-input :multiple="true" type="upload" @input="getFiles($event)" :value="files"></argon-input>
        </div>
    </div>
    <div class="row">
        <br/>
        <div v-if="!meeting || (meeting && currentUser.id == meeting.organizer_id)"class="col-md-2"><button :disabled="v.form.$invalid || (meeting && currentUser.id != meeting.organizer_id)" type="submit" class="ms-2 btn btn-success rounded-0">{{ meeting ? "Edit meeting" : "Set meeting"}}</button></div>
        <div v-if="meeting && currentUser.id == meeting.organizer_id" class="col-md-3"><button :disabled="v.form.$invalid" class="ms-2 btn btn-warning rounded-0">Delete meeting</button></div>
    </div>
 </form>
</template>

<script>
import { Jaas } from '@/api_services/jaas'
import ArgonInput from '../../common/Input/ArgonInput.vue'
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {
  User
} from '@/api_services/user';

export default {
    props: {
        query: Object,
        meeting: Object,
        currentUser: Object
    },
    components: {
        ArgonInput,
        Datepicker
    },
    data() {
        return {
            api: null,
            options: [],
            suppliers: null,
            attendance_status: null,
            form: {
                recipients: [],
                title: null,
                description: "",
                start_datetime: null,
                end_datetime: null
            },
            files: [],
            selected_files: [],
            file_name: null
        }
    },
    setup: () => ({
        v: useVuelidate()
    }),
    created: function () {
        if(this.meeting) {
            this.isInProgress
            let self = this
            let tempRecipients = []
            // this.files = this.meeting.files
            this.selected_files = this.meeting.files
            this.meeting.invitees.forEach(
                result => {
                    self.options.push({
                    value: result.id,
                    label: result.company.name
                    })
                    tempRecipients.push(result.id)

                    if(result.id == self.currentUser.id) self.attendance_status = result.pivot.attendance_status
            })

            let user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const options = {
                timeZone: user_timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };
            
            let start_datetime = new Date(this.meeting.start_datetime + " UTC");
            start_datetime = new Intl.DateTimeFormat('en-US', options).format(start_datetime);
            let end_datetime = new Date(this.meeting.end_datetime + " UTC");
            end_datetime = new Intl.DateTimeFormat('en-US', options).format(end_datetime);
            
            this.form = {
                title: this.meeting.title,
                recipients: tempRecipients,
                description: this.meeting.description,
                start_datetime: start_datetime,
                end_datetime: end_datetime
            }

        } else 
        {
            this.initForm();
            this.getSuppliers()
        }
    },
    computed: {
        isInProgress() {
            if(!this.meeting) return false
            
            let now = new Date(this.getDateNow() + ' ' + this.getTimeNow())
            let startDatetime = this.meeting.start_datetime
            let endDateTime = this.meeting.end_datetime
            
    
            if(now >= startDatetime && now < endDateTime) return true
            
            return false
        }
    },
    validations() {
        return {
            form: { 
                title: {
                required,
                $autoDirty: true
                },
                recipients: {
                required,
                $autoDirty: true
                },
                start_datetime: {
                required,
                $autoDirty: true
                },
                end_datetime: {
                required,
                $autoDirty: true
                },
            }
        }
    },
    methods: {
        getDateNow() {
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            return date;
        },
        getTimeNow () {
            const today = new Date();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            return time
        },
        getSuppliers() {
            this.globalState.loading = true 
            User.getUsersByRole({role: 'SUPPLIER'})
                .then(result => {
                this.options = []
                this.form.recipients = []
                let self = this
                if (result.data.users) {
                    result.data.users.forEach(
                    result => {
                        self.options.push({
                        value: result.id,
                        label: result.company.name
                        })
                        self.form.recipients.push(result.id)
                    }
                    )
                }
                })
                // .catch(error => {
                // this.enablePageNotFound()
                // console.error('error:', error.response.data)
                // })
                .finally(() => {
                this.globalState.loading = false
                })
        },
        createRandomString(length) {
            const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let result = "";
            for (let i = 0; i < length; i++) {
                result += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return result;
        },
        getForm() {
            let formData = new FormData()
            formData.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
            formData.append('room_name', this.createRandomString(8))
            formData.append('title', this.form.title)
            formData.append('date', this.form.date)
            formData.append('description', this.form.description)
            formData.append('recipients', this.form.recipients)
            formData.append('start_datetime', this.form.start_datetime)
            formData.append('end_datetime', this.form.end_datetime)
            if(this.meeting) formData.append('meeting_id', this.meeting.id)


            if (this.files.length) {
                this.files.forEach(function(file) {
                    formData.append('files[]', file)
                })
                // formData.append('file', this.files[0])
                // formData.append('file_name', this.file_name)
            }

            return formData
            },
            async submit() {
            const result = await this.v.$validate()
            if (!result) return

            if(!this.meeting) {
                Jaas.scheduleMeeting(this.getForm())
                .then(result => {
                    this.$toast.success('Meeting has been scheduled.')
                    this.$emit('close_modal')
                    this.$emit('update')
                })
            } else {
                Jaas.updateMeeting(this.meeting.id ,this.getForm())
                .then(result => {
                    this.$toast.success('Meeting has been updated.')
                    this.$emit('close_modal')
                    this.$emit('update')
                })
            }
            
        },
        getFiles(files) {
            let self = this
            this.files = []
            if (files.length) {
                files.forEach(file => {
                self.files.push(file.raw)
                })
            }
        },
        initForm() {
            this.form = {
                recipients: [],
                title: null,
                description: "",
                start_datetime: this.getDateNow() + ' ' + this.getTimeNow(),
                end_datetime: this.getDateNow() + ' ' + this.getTimeNow()
            }
            this.files = []
        },
        attendanceStatusString(status) {
            switch(status) {
                case 0: return 'Not Responded'
                case 1: return 'Will Attend'
                case 2: return 'Declined'
                case 3: return 'Tentative'
            }
        },
        updateAttendanceStatus() {
            Jaas.updateAttendance(this.currentUser.id, {meeting_id: this.meeting.id, attendance_status: this.attendance_status}).
            then( result => {
                this.$toast.success('Attendance has been updated');
            })
        }
    }
}
</script>
