import api from './api'

const API = '/api/products'

export const Product = {
  get: function(params) {
    return api.get(API, params)
  },
  downloadResult: function(params) {
    return api.get(API+'/download-search-result', params)
  },
  sendResult: function(params) {
    return api.get(API+'/send-results-to-suppliers', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, {params: params})
  },
  updateStatus: function(id, form) {
    return api.update(API + '/' + id+'/update-status', form)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  update: function(form) {
    let id = form.get('id')
    return api.updateFormData(API+'/'+id, form)
  },
  uploadImages: function(form) {
    let id = form.get('id')
    return api.updateFormData(API+'/'+id+'/images', form)
  },
  setPrimaryImage: function(params) {
    return api.create(API+'/'+params.id+'/set_primary_image', params)
  },
  saveSpecificationAttachment: function(params) {
    return api.createFormData(API+'/save-product-specs-attachments', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  requestDelete: function(params) {
    return api.update(API+'/request_delete/'+params.id, params)
  }
}
