<template>
<div>
  <h2 class="text-white">{{ all ? 'All Card Imports':'Import Merchant Services Data' }}</h2>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h5>Files</h5>
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-md-2">
                <el-input v-model="term" placeholder="Search" class="input-with-select" v-if="all">
                  <template #append>
                    <el-button><i class="fa fa-search"></i></el-button>
                  </template>
                </el-input>
              </div>
              <div class="col-md-10" style="text-align: right;">
                <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="getImports()">
                  <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                </button>
                <button type="button" class="btn bg-gradient-primary btn-sm mb-0" @click="create()" v-if="$store.state.isAllowed('CARD_IMPORTS', 'CREATE') && add">
                  <i class="fa-solid fa-cloud-arrow-up me-1"></i> Import
                </button>
              </div>
            </div>
            <div class="text-end">
            </div>
          </div>
          <br>

          <div class="table-responsive">
            <table class="table table-fluid align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-if="all">
                    Company
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Date Uploaded
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Last Updated
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    File
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    file type
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Created by
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Zero Savings Activated</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Status
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Message
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!card_imports.length">
                  <td v-if="loading" colspan="100%" class="text-sm">
                    <i class="fas fa-cog fa-spin me-2"></i>Loading...
                  </td>
                  <td v-else colspan="100%">No data</td>
                </tr>
                <tr v-else class="text-sm" v-for="card_import in card_imports" :key="card_import.id">
                  <td class="text-xs" v-if="all">{{ card_import.company.id}}-{{ card_import.company.name }}</td>
                  <td class="text-xs">{{ $store.state.formattedDateTime(card_import.created_at) }}</td>
                  <td class="text-xs">{{ $store.state.formattedDateTime(card_import.updated_at) }}</td>
                  <td class="text-xs">
                    <a :href="card_import.absolute_path" target="_blank">Download</a>
                  </td>
                  <td class="text-xs">{{ card_import.file_type }}</td>
                  <td class="text-xs">
                    {{ card_import.user ? card_import.user.name : ' ' }}
                  </td>
                  <td class="text-xs"><b :class="(card_import.zero_savings_activated == 1 ? 'text-success': '')">{{ (card_import.zero_savings_activated == 1 ? 'Yes': "No") }}</b></td>
                  <td>
                    <span class="badge badge-info" v-if="card_import.status == 0">New</span>
                    <span class="badge badge-warning" v-if="card_import.status == 1">In-progress</span>
                    <span class="badge badge-success" v-if="card_import.status == 2">Completed</span>
                    <span class="badge badge-danger" v-if="card_import.status == 3">Error</span>
                    <span class="badge badge-primary" v-if="card_import.status == 4">On Hold</span>
                  </td>
                  <td class="text-wrap">
                    {{ card_import.message }}
                    {{
                        card_import.attempts > 0
                          ? 'Import Attempt: ' + card_import.attempts
                          : ''
                      }}
                  </td>
                  <td>
                    <div></div>
                    <a v-allowed="'card_imports,update'" class="link me-3" @click="hold(card_import)" v-tooltip="'Hold or Stop'" v-if="
                          card_import.status == 0 || card_import.status == 1
                        ">
                      <i class="fa-solid fa-hand text-danger"></i>
                    </a>
                    <a class="link me-3" @click="regenerate(card_import)" v-tooltip="'Reset import process'" v-allowed="'card_imports,update'">
                      <i class="fa-solid fa-arrows-rotate text-secondary"></i>
                    </a>
                    <a class="link me-3" @click="showRemove(card_import)" ref="showRemove" v-tooltip="'Delete'" v-allowed="'card_imports,delete'">
                      <i class="fas fa-trash text-secondary"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer pt-0">
          <pagination :meta="meta" v-on:update="getImports()" />
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="showForm" title="Upload File" width="500" :before-close="handleClose">
    <div v-if="original">
      <CompanyZeroSavingsToggle
        :type="'merchant'"
        :value="zero_savings"
      ></CompanyZeroSavingsToggle>
      <br>
      <card-import-form @save="getImports()"></card-import-form>
    </div>
  </el-dialog>
  <el-dialog id="modal-remove" v-model="dialogVisible" title="Confirm Delete?" width="500" :before-close="handleClose">
    <div v-if="to_remove">
      You are about to remove file no. {{ to_remove.id }}, Proceed?
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="danger" @click="remove">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>
</div>
</template>

<script>
import {
  CardImport
} from '@/api_services/card_import'
import CardImportForm from './CardImportForm.vue'
import CompanyZeroSavingsToggle from '@/components/common/CompanyZeroSavingsToggle.vue'

export default {
  props: {
    all: {
      type: Boolean,
      default: false
    },
    add: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      zero_savings: this.$store.state.savedUser.company.merchant_activate_zero_savings,
      dialogVisible: false,
      card_imports: [],
      selected: null,
      original: null,
      to_remove: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      clear_upload: 0,
      no_company: false,
      showForm: false,
      loading: false
    }
  },
  components: {
    CardImportForm, CompanyZeroSavingsToggle
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) this.getImports()
    }
  },
  created: function () {
    this.getImports()
  },
  methods: {
    handleClose() {
      this.showForm = false
    },
    search() {
      this.meta.page = 1
      this.getImports()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.loading = true
      this.globalState.loading = true
      CardImport.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getImports()
        })
        .catch(error => {

        }).finally(() => {
          this.globalState.loading = false
        })
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    regenerate(card) {
      let obj = Object.assign({}, card)
      obj.status = 1
      this.globalState.loading = true
      CardImport.reset(obj)
        .then(result => {
          this.$toast.success('Saved successfully')
          this.getImports()
        })
        .catch(error => {

        })
        .finally(() => {
          this.globalState.loading = false
        })
    },
    hold(card) {
      let obj = Object.assign({}, card)
      obj.status = 1
      this.globalState.loading = true
      CardImport.hold(obj)
        .then(result => {
          this.$toast.success('Saved successfully')
          this.reset()
          this.getImports()
        })
        .catch(error => {

        })
        .finally(() => {
          this.globalState.loading = false
        })
    },
    getImports() {
      this.loading = true
      this.handleClose()
      this.card_imports = []
      CardImport.get({
          params: {
            page: this.meta.page,
            all: this.all ? 1:0,
            term: this.all ? this.term : null
          }
        })
        .then(result => {
          if (result.data.imports) this.card_imports = result.data.imports
          this.meta = result.data.meta
        })
        .catch(error => {

        })
        .finally(() => {
          this.loading = false
        })
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {
        file: null
      }
      this.selected = Object.assign({}, this.original)
      this.showForm = true
    }
  },
}
</script>
