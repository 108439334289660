<template>
  <form class="d-inline" @submit.prevent="onSubmit">
    <div class="modal-body">
      This action will remove card transactions with submit date between {{ startDate }} and {{ endDate }} permanently.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isDeleting">Cancel</button>
      <button type="submit" class="btn btn-danger" :disabled="isDeleting">{{isDeleting ? 'Deleting...' : 'Yes, delete it!'}}</button>
    </div>
  </form>
</template>

<script>
import { CardTransaction } from '@/api_services/card_transaction'

  export default {
    props: {
      startDate: String,
      endDate: String
    },
    methods: {
      onSubmit() {
        this.isDeleting = true;
        CardTransaction.deleteBySubmitDate({start_date: this.startDate, end_date: this.endDate})
        .then(result => {
          this.$toast.success('Card transactions have been removed')
          this.isDeleting = false;
          this.$emit('update', true)
        })
        .catch( error => {
          this.$toast.error('An error occured while removing card transactions')
          this.isDeleting = false;
        })
      },
      
    },
    data() {
      return {
        isDeleting: false
      }
    }
  }
</script>
