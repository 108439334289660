import api from './api'

const API = '/api/users'

export const User = {
  currentUser: function(params) {
    return api.get(API + '/me', {params: params})
  },
  updateFcm: function(params) {
    return api.create(API + '/update-fcm', params)
  },
  skip2fa: function(params) {
    return api.create(API + '/skip-2fa', params)
  },
  verify2fa: function(params) {
    return api.create(API + '/verify-2fa', params)
  },
  resendOtp: function(params) {
    return api.create(API + '/resend-otp', params)
  },
  uploadFaca: function(form) {
    return api.createFormData(API + '/upload-faca', form)
  },
  generateFaca: function(form) {
    return api.download(API + '/generate-faca', {params: form})
  },
  updatePassword: function(params) {
    return api.create(API + '/update_password', params)
  },
  getUsersByRole: function(params) {
    return api.get(API + '/users-roles', { params: params })
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  updateCompany: function(params) {
    return api.update(API + '/' + params.id + '/update-company', params)
  },
  update: function(params) {
    return api.update(API + '/' + params.id, params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id, params)
  }
}
