<template> 
    <div>
    
      <div v-if="!pin_notice && $loading" >
        
      </div>
      <div v-else-if="!pin_notice" >
        
      </div>
      <div class="row" v-else>
        <div>
          <el-alert v-if="notification" :title="notification.message" type="success" effect="dark">
          </el-alert>
          <h3 class="" :class="!selected_framework ? 'text-white' : ''">
            {{ pin_notice.name }}
          </h3>
     
          <div class="card my-3">
            <div class="card-body">
              <div v-html="pin_notice.main_description"></div>
            </div>
          </div>
    
          <el-drawer v-model="showDrawer" :with-header="true" :showClose="false" direction="rtl" size="80%">
            <!-- drawer content here -->
            <supplier-hub-welcome></supplier-hub-welcome>
            <div class="text-center">
              <el-button class="btn btn-warning" type="warning" @click="showDrawer = false">CLOSE</el-button>
            </div>
          </el-drawer>
        </div>
        <div>
      <el-button type="primary" @click="handleViewSpecsList()" v-tooltip="'View Specifications'">View Framework
        Lots</el-button>

        <el-button v-if="
            view_itt == 1 && 
            pin_notice?.itt_framework?.id &&
            pin_notice?.itt_framework?.status != IttStatuses.PENDING
            " type="success" @click="viewITT()" v-tooltip="'View ITT'">
          <i class="fa fa-eye"></i>&nbsp;View ITT</el-button>

        
    </div>
        <div>
          
        </div>
    
        <div v-allowed="'common_files,view'" class="col-md-6">
          <common-files :if_pin_notice="1" :itt_framework="pin_notice"></common-files>
        </div>
        <div v-allowed="'my_files,view'" class="col-md-6">
          <my-files :if_pin_notice="1" :itt_framework="pin_notice"></my-files>
        </div>
    
        <div v-allowed="'questions,view'" class="col-md-6">
          <public-questions-with-answers-list :if_pin_notice="1" :itt_framework="pin_notice"></public-questions-with-answers-list>
        </div>
    
        <div v-allowed="'questions,view'" class="col-md-6">
          <my-questions-list :if_pin_notice="1" :itt_framework="pin_notice"></my-questions-list>
        </div>
      </div>
    </div>

    <el-drawer v-model="showSpecsList" :showClose="true" size="80%" :with-header="true" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <div class="container">
        <supplierhub-lots-list :if_pin_notice="1" :itt_framework="pin_notice"></supplierhub-lots-list>
      </div>
    </el-drawer>

    <el-drawer
      v-model="showITTDashboard"
      :show-close="true"
      size="80%"
      direction="rtl" 
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div v-if="itt_framework" class="container">
        <supplier-hub-framework-view :selected_framework="itt_framework"></supplier-hub-framework-view>
      </div>
    </el-drawer>

    </template>
    
    <script>
    import MyQuestionsList from '../questions_and_answers/MyQuestionsList.vue'
    import PublicQuestionsWithAnswersList from '../questions_and_answers/PublicQuestionsWithAnswersList.vue'
    import CommonFiles from '../SuppliersHub/CommonFiles.vue'
    import MyFiles from '../SuppliersHub/MyFiles.vue'
    import SupplierHubWelcome from '../SuppliersHub/SupplierHubWelcome.vue'
    import SupplierhubLotsList from '../SuppliersHub/SupplierhubLots/SupplierhubLotsList.vue'
    import { defineAsyncComponent } from 'vue'
    import SupplierHubFrameworkView from '../SuppliersHub/SupplierHubFrameworkView.vue'
    import { IttFramework } from '@/api_services/itt_framework'
    import { mapState } from 'vuex'

    export default {
      computed: {
        ...mapState(['IttStatuses', 'ProcurementProjectStatuses'])
      },
      watch: {
        selected_framework: function() {
          if (this.selected_framework) {
            this.pin_notice = Object.assign({}, this.selected_framework)
          }
        }
      },
      props: ['selected_framework', 'view_itt'],
      components: {
        CommonFiles,
        MyFiles,
        PublicQuestionsWithAnswersList,
        MyQuestionsList,
        SupplierHubWelcome,
        SupplierhubLotsList,
        UserIttFrameworksList: defineAsyncComponent(() =>
          import('../itt_frameworks/UserIttFrameworksList.vue')
        ),
        SupplierHubFrameworkView
      },
      data() {
        return {
          itt_framework: null,
          showITTDashboard: false,
          showSpecsList: false,
          showFrameworks: false,
          showDrawer: false,
          drawer: false,
          notification: null,
          loading: true,
          pin_notice: null
        }
      },
      created: function () {
        if (this.selected_framework) {
          this.pin_notice = Object.assign({}, this.selected_framework)
        }
      },
      methods: {
        viewITT(){
          this.itt_framework = Object.assign({}, this.pin_notice.itt_framework)
          this.showITTDashboard = true
        },
        handleViewSpecsList() {
          this.showSpecsList = true
        },
        handleViewFrameworks() {
          this.showFrameworks = true
        }
      }
    }
    </script>
    