import api from './api'

const API = '/api/procurement_projects'

export const ProcurementProjects = {
  get: function(params) {
    return api.get(API, params)
  },
  getProcurementProjectTypes: function(params) {
    return api.get(API + "/procurement_project_types", params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  },
  load_history: function(id, params) {
    return api.get(API + '/load_history/' + id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
