export const InvoicePaymentStatuses = Object.freeze({
  DRAFT: 0,
  APPROVED: 1,
  PAID: 2
})

export const InvoicePaymentStatusTexts = Object.fromEntries(
  Object.entries(InvoicePaymentStatuses).map(a => a.reverse())
)

export const InvoiceItemsStatuses = Object.freeze({
  REGULAR: 0,
  DEDUCTION: 1,
  DISCOUNT: 2
})

export const InvoiceItemsStatusTexts = Object.fromEntries(
  Object.entries(InvoiceItemsStatuses).map(a => a.reverse())
)


export const InvoiceTypes = Object.freeze({
  CARD_TRANSACTIONS: 0,
  BANK_REPORT_TRANSACTIONS: 1,
  COMPANY_INVOICE: 2
})

export const CommonStatuses = Object.freeze({
  QUEUED: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3
})

export const ProductStatuses = Object.freeze({
  STATUS_NEW: 0,
  STATUS_PROCESSING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3,
  STATUS_DELETING: 4
})

export const CommonStatusTexts = Object.fromEntries(
  Object.entries(CommonStatuses).map(a => a.reverse())
)

export const ProcurementProjectStatuses = Object.freeze({
  CODE_PENDING: "PEN",
  CODE_SUBMITTED: "SUB",
  CODE_ACTIVE: "ATC",
  CODE_PIN_ARCHIVED: "PAR",
  CODE_DOC_FINALIZE: "DOC",
  CODE_ITT_PENDING: "ITP",
  CODE_ITT_SUBMITTED: "ITS",
  CODE_ITT: "ITT",
  CODE_MARKING_STAGE: "MKR",
  CODE_IAR: "IAR",
  CODE_COM: "COM"
})

export const BuyerRoleName = "BUYER"
export const SupplierRoleName = "SUPPLIER"
export const OriginatorRoleName = "ORIGINATOR"

export const PinNoticeStatuses = Object.freeze({
  PENDING: 0,
  ACTIVE: 1,
  ARCHIVED: 2
})

export const IttStatuses = Object.freeze({
  PENDING: 2,
  ACTIVE: 1,
  ARCHIVED: 0
})

export const QaQuestionTypes = Object.freeze({
  TYPE_BOOLEAN: 1,
  TYPE_TEXT: 2,
  TYPE_FILE: 3,
  TYPE_HAS_SUB_QUESTION: 4,
  TYPE_TABLE: 5
})

export const GuidanceEvaluationAnswerRequirements = Object.freeze({
  ANSWER_REQUIREMENT_ALL: 0,
  ANSWER_REQUIREMENT_ATLEAST_1: 1,
  ANSWER_REQUIREMENT_NO_ANSWER: 2,
})

export const GuidanceEvaluationAnswerType = Object.freeze({
  NEGATIVE_ANSWER: 0,
  POSITIVE_ANSWER: 1,
})

export const QaSubmissionStatuses = Object.freeze({
  STATUS_PENDING: 0,
  STATUS_VERIFYING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3
})
