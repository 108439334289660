import api from './api'

const API = '/api/product_search_results'

export const ProductSearchResult = {
  get: function(params) {
    return api.get(API, params)
  },
  getDetailedCostComparison: function(params) {
    return api.get(API + '/detailed-cost-comparison', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  uploadAdditionalStatements: function(id, params) {
    return api.createFormData(
      API + '/' + id + '/upload-additional-statements',
      params
    )
  },
  notifySupplierNotInterested: function(params) {
    return api.create(API + '/' + params.id + '/notify-supplier-not-interested', params)
  },
  uploadBuyerCallOffDocument: function(id, form) {
    return api.createFormData(API + '/' + id + '/upload-buyer-call-off-document', form)
  },
  uploadSupplierCallOffDocument: function(id, form) {
    return api.createFormData(API + '/' + id + '/upload-supplier-call-off-document', form)
  },
  notifySupplier: function(params) {
    return api.create(API + '/' + params.id + '/notify-supplier', params)
  },
  reply: function(params) {
    return api.create(API + '/' + params.id + '/reply', params)
  },
  updateFinalPrice: function (id, params) {
    return api.create(API + '/' + id + '/update-final-price', params)
  },
  updateProcessedStatus: function (id, params) {
    return api.create(API + '/' + id + '/update-processed-status', params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id)
  }
}
