<template>
  <div style="padding: 10px">
    <div class="container">
      To Upload, click the <b>Drop files here or click to upload button</b>, prompting a file picker dialog box to appear. Simply select the desired file(s) by clicking on them. For multiple selections, hold down the <b>Ctrl</b>  key (<b>Command</b> on Mac) while choosing the files. Once your selection is complete, click <b>Open</b> or the respective option to commence the upload process
    </div>
    <form @submit.prevent="onSubmit()">
      <div class="form-group" v-if="!personal && !common">
        <label for="exampleFormControlSelect1">Type</label>
        <select class="form-control" id="exampleFormControlSelect1" v-model="form.type">
          <option v-for=" type,i in $store.state.supplier_hub_file_types" :key="i" :value="i">{{ type }}</option>
        </select>
      </div>
      <!-- <argon-input
        type="text"
        :value="form.file_name"
        field="File name"
        :required="true"
        :label="true"
        @update="updateField('file_name', $event)"
      ></argon-input> -->

      <argon-input
        :multiple="true"
        type="upload"
        :max-size="30000000"
        :value="form.files"
        field="Files"
        :required="true"
        :label="true"
        @update="updateField('files', $event)"
      ></argon-input>

      <button type="submit" class="btn btn-info" :disabled="!isValid">
        Upload
      </button>
    </form>
  </div>
</template>

<script>
import ArgonInput from '@/components/common/Input/ArgonInput.vue'
import { SupplierHubFile } from '@/api_services/supplierhub_file'
import Swal from 'sweetalert2'

export default {
  components: { ArgonInput },
  props: ['selected', 'personal','common','user', 'if_pin_notice', 'pin_notice_id', 'itt_framework_id'],
  data() {
    return {
      validFields: {
        files: false
      },
      form: {
        files: null,
        type: 0
      }
    }
  },
  watch: {
    selected: function() {
      if (this.selected) this.form = Object.assign({}, this.selected)
      if (this.personal) this.form.type = 0
      if (this.common) this.form.type = 1
    }
  },
  created: function () {
    if (this.selected) this.form = Object.assign({}, this.selected)
    if (this.personal) this.form.type = 0
    if (this.common) this.form.type = 1

    //if(this.if_pin_notice == 1){
      if(this.$store.state.isUserAnOriginator() || this.$store.state.isUserABuyer()){
        this.form.type = 1
      }
    //}
  },
  methods: {
    setFiles(files) {
      this.files = files
    },
    updateField(name, newItem) {
      this.validFields[name] = newItem ? true : false
      this.form[name] = newItem
    },
    onSubmit() {
      this.loading = true
      SupplierHubFile.create(this.getFormData())
        .then(result => {
          // this.$toast.success('Uploaded successfully')
          Swal.fire({
            title: '<strong>File Submitted</strong>',
            icon: 'info',
            html:
              'Your <b>document</b> has been successfully uploaded , ',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,  
          })
          this.$emit('update', result.data.file)
        })
        .catch(error => {
          // this.$toast.error('An error occured while fetching common files')
          // console.error('error:', error.response.data)
        }).finally(res => {
          this.loading = false
        })
      // this.$emit('success', this.form)
    },
    getFormData() {
      var formData = new FormData()
      formData.append('file_name', this.form.file_name)
      formData.append('type', this.form.type)

      if(this.pin_notice_id){
        formData.append('pin_notice_id', this.pin_notice_id)
      }

      if(this.itt_framework_id){
        formData.append('itt_framework_id', this.itt_framework_id)
      }
      
      if (this.user) formData.append('uploaded_by', this.user.id)
      if (this.form.files) {
        this.form.files.forEach(file => {
          formData.append('file_names[]', file.name)
          formData.append('files[]', file.raw)
        })
      }

      return formData
    }
  },
  computed: {
    isValid() {
      let valid = true
      for (var i in this.validFields) {
        if (!this.validFields[i]) valid = false
      }

      return valid
    }
  }
}
</script>
