<template>
    <Form @submit="submit" >
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update' : 'New' }} Lot
          </h5>
        </div>
        <div class="card-body">
          <div class="form-group">
              <label>Name</label>
                <Field
                  type="text"
                  name="name"
                  v-model="form.name"
                  placeholder="Name"
                  class="form-control" 
                  :rules="validateRequire"
                />
                <ErrorMessage style="color:red" name="name" />
            </div>

          <div style="text-align: right;">
            <el-button type="primary" native-type="submit" size="small">Submit</el-button>
          </div>
        </div>
      </div>
    </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { SupplierhubLot } from '@/api_services/supplierhub_lot'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import * as yup from 'yup';

export default {
  components: { Form, Field, ErrorMessage },
  props: {
    lot: {
      type: Object,
      required: false
    },
    if_pin_notice: {
      type: Number,
      required: false
    },
    pin_notice_id: {
      type: Number,
      required: false
    },
    itt_framework_id: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: null,
        if_pin_notice: null,
        pin_notice_id: null,
        itt_framework_id: null
      },
      loading: false,
      min: 1,
    }
  },
  beforeUnmount() {
    this.form = {}
  },
  watch: {
    lot: function () {
      this.form = Object.assign({}, this.lot)
    }
  },
  created: function () {
    if (this.lot) this.form = Object.assign({}, this.lot)

    if(this.if_pin_notice == 1){
      this.form.if_pin_notice = this.if_pin_notice
      this.form.pin_notice_id = this.pin_notice_id
    }
    else{
      this.form.itt_framework_id = this.itt_framework_id
    }
  },
  methods: {
    validateRequire(value) {
      // if the field is empty
      if (!value) {
        return 'This field is required';
      }
      // All is good
      return true;
    },
    schema(){
      return yup.object({
        name: yup.string().min(this.min),
      });
    },
    submit() {
      this.$loading = true

      let request = this.form.id
        ? SupplierhubLot.update(this.form)
        : SupplierhubLot.create(this.form)

      request
        .then(result => {
          this.$toast.success('Lot name has been saved')
          this.$emit('close', true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
