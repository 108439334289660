<template>
  <div>
    <div class="card-body px-0 pb-2">
      <div class="table-responsive">
        <div class="float-end px-2">
          <button @click="refresh()" class="btn bg-gradient-secondary btn-sm" ref="showRemove" v-tooltip="'Refresh'"><i class="fa-solid fa-arrow-rotate-right text-white"></i></button>
        </div>
        <table class="table table-fluid align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Submit Date</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!reports.length">
              <td v-if="isLoading" colspan="100%" class="text-sm">
                  <i class="fas fa-cog fa-spin me-2"></i>Loading...
              </td>
              <td v-else colspan="100%">No data</td>
            </tr>
            <tr v-else class="text-sm" v-for="report in reports" :key="report.id">
              <td>{{ getParameters(report) }}</td>
              <td>{{ report.user? report.user.name : "" }}</td>
              <td>{{ report.created }}</td>
              <td>
                <span class="badge badge-secondary" v-if="report.status == 0">{{ statuses[report.status] }}</span>
                <span class="badge badge-info" v-if="report.status == 1">{{ statuses[report.status] }}</span>
                <span class="badge badge-success" v-if="report.status == 2">{{ statuses[report.status] }}</span>
                <span class="badge badge-danger" v-if="report.status == 3">{{ statuses[report.status] }}</span>
                <span v-if="report.invoice" style="color: #bdbaba;">{{ report.invoice.invoice_items.length > 1 ? '('+report.invoice.invoice_items.length+' invoice items)' : '' }}</span>
              </td>
              <td style="width: 5%">
                <a class="link me-3" @click="emailReport(report)" v-if="report.status == 2 && $helpers.isVisible('COST_DATA_REPORT','EMAIL')" v-tooltip="'Email Report'"> 
                  <i class="fa-regular fa-paper-plane text-secondary"></i>
                </a>
                <a class="link me-3" @click="generateReport(report)" v-if="report.status == 2 && $helpers.isVisible('COST_DATA_REPORT','PRINT')" v-tooltip="'View Report'">
                  <i class="fa-solid fa-table-cells text-secondary"></i>
                </a>
                <!-- <a class="link me-3" @click="showInvoice(report)" v-if="report.status == 2 && report.invoice && $helpers.isVisible('INVOICE','UPDATE')" v-tooltip="'View Invoice'">
                  <i class="fa-solid fa-paintbrush text-secondary"></i>
                </a>
                <a class="link me-3" @click="downloadInvoice(report)" v-if="report.status == 2 && report.invoice && $helpers.isVisible('INVOICE','DOWNLOAD')" v-tooltip="'Download Invoice'">
                  <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                </a> -->
                <a class="link me-3" @click="regenerate(report)" v-if="$helpers.isVisible('COST_DATA_REPORT','CREATE')" v-tooltip="'Reset'">
                  <i class="fa-solid fa-arrows-rotate text-secondary"></i>
                </a>
                <a class="link me-3" @click="holdInProgress(report)" v-if="$helpers.isVisible('COST_DATA_REPORT','CREATE') && report.status == 1" v-tooltip="'Hold'">
                  <i class="fa-solid fa-hand text-secondary"></i>
                </a>
                <a class="link me-3" @click="showRemove(report)" ref="showRemove" v-if="$helpers.isVisible('COST_DATA_REPORT','DELETE')" v-tooltip="'Delete'">
                  <i class="fas fa-trash text-secondary"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> 
    
    <el-dialog
      id="modal-remove"
      v-model="modal_remove"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove file no. {{ to_remove.id }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="modal_remove = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-model="dialogVisible"
      title="Summary Report"
      width="80%"
      :before-close="handleClose"
    >
    <cost-data-report-print-component v-if="to_print_report" :data_report="to_print_report" :to_email_report="to_email_report" @save="refresh()"></cost-data-report-print-component>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      id="cost-data-report-invoice-modal"
      v-model="invoice_modal"
      title="Invoice"
      width="80%"
    >
    <cost-data-invoice-component v-if="to_print_report" :cost_data_report="to_print_report" @update="hideInvoice($event)"></cost-data-invoice-component>
      
    </el-dialog>


  </div>
</template>

<script>
import { CardTransaction } from '@/api_services/card_transaction'
import { Invoice } from '@/api_services/invoice'
import CostDataReportPrintComponent from './cost_data_report_print_component.vue'
import CostDataInvoiceComponent from './cost_data_invoice_component.vue'
import axios from 'axios'
import $ from 'jquery'


export default {
  components: { CostDataReportPrintComponent, CostDataInvoiceComponent },
  data() {
    return {
      invoice_modal: false,
      modal_remove: false,
      dialogVisible: false,
      isLoading: true,
      to_print_report: null,
      to_remove: null,
      statuses: ['New','Generating','Done','Error'],
      reports: [],
      cost_per_processed: [],
      to_email_report: 0,
      show_invoice: false,
      fields: ['id','parameters','company','user','message', 'created' , 'status' ,'action']
    }
  },
  created: function() {
    this.getReports()
  },
  methods: {
    refresh() {
      this.getReports()
    },
    showInvoice(report) {
      this.to_print_report = Object.assign({}, report);
      this.invoice_modal = true
    },
    downloadPDF(pdf,fileName) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        // const fileName = "vct_illustration.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    },
    downloadInvoice(report) {
      Invoice.download(report.invoice.id)
        .then(result => {
          this.downloadPDF(result.data, 'invoice_'+report.invoice.id+'.pdf')
        })
        .catch(error => {
          this.$toast.error('An error occured while downloading invoice')
          console.error('error:', error.response.data)
        })
      
    },
    hideInvoice(invoice) {
      let self = this;
      this.reports.forEach( (report, i) => {
        if (report.id == self.to_print_report.id && invoice) self.reports[i].invoice = invoice
      });
      this.invoice_modal = false
    },
    generateReport(report) {
      this.to_print_report = Object.assign({}, report);
      this.to_email_report = 0;
      this.dialogVisible = true
    },
    emailReport(report) {
      this.to_print_report = Object.assign({}, report);
      this.to_email_report += 1;
      this.dialogVisible = false
    },
    getParameters(report) {
      let string = ''
      if (report.parameters.submit_date) {
        string += report.parameters.submit_date
      }
      return string
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      CardTransaction.deleteCostDataReport(this.to_remove.id)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.modal_remove = false
          })
          this.getReports()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing categry')
          console.error('error:', error.response.data)
        })
    },
    getReports() {
      this.isLoading = true
      CardTransaction.costDataReports()
        .then(result => {
          this.reports = result.data.collection
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching cost data reports')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    regenerate(obj) {
      CardTransaction.resetCostDataReport(obj.id).then(result => {
          this.$toast.success('Saved successfully')
          this.getReports()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving import')
          console.error('error:', error.response.data)
        })
    },
    holdInProgress(obj) {
      CardTransaction.resetCostDataReport(obj.id).then(result => {
        this.$toast.success('Saved successfully')
        this.getReports()
      })
      .catch(error => {
        this.$toast.error('An error occured while saving import')
        console.error('error:', error.response.data)
      })
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.modal_remove = true
    },
  }
}
</script>

<style scoped>

</style>