<template>
  <div class="container-fluid">
    <loading-component :loading="loading" :message="loading_title_message" :content="loading_content_message"></loading-component>
    
    <div class="row">
      <div class="card p-3 text-lg mb-3">
        <p>Welcome to the CPRAS Procurement Platform's Product Search.</p>
        <p>
          To begin, please select the LOT you are interested in from the list below and enter your desired contract length in years. 
        </p>
        <p>
          You will then see the overall weightings the Framework Managers have applied for Product/Service Match, Price, Social Value and Management Information. You are able to adjust these weightings to align with your organisation’s preferences.
        </p>
        <p>
          When you select the 'Search' button, you will be shown all qualifying suppliers with scores based on the overall weightings applied. At this stage, you can adjust these overall weightings again or apply your own granular weightings to refine your results further and ensure they reflect your organisation's priorities and requirements. Each element of a product or service’s specification will be listed for you to rate according to its importance, and you can highlight any elements that are mandatory to you.
        </p>
        <p>
          Once you have completed your search, you will be able to view a provisional report from the qualifying suppliers and request their final pricing.
        </p>
        <p>
          If you have any questions please contact our dedicated support team via the link on your Dashboard or sidebar. We are here to assist you through every stage of the procurement process.
        </p>
      </div>

      <div class="col-md-12 mb-3" v-if="error_message" >
        <!-- <el-alert type="error">{{ error_message }}</el-alert> -->
        <div class="alert alert-info text-white" role="alert">
          <h4 class="alert-heading">Important Notice!</h4>
          <p>{{ error_message }}</p>
          <hr>
        </div>
      </div>

      <div :class="(params.back == 1) ? 'col-md-12' : 'col-md-5'">
        <div v-if="params.step <= 1" :class="(params.step <= 1 && params.back > 0) ? '' : ''">

          <div>
            <!--<div class="m-2" v-if="params.step <= 1 && params.back == 0">
              <el-button type="warning" size="small" @click="saveUrl('list')"><i class="fa-solid fa-bookmark"></i> Show Saved Searches</el-button>
            </div>-->
        
            <search-panel-component
              v-if="params.step <= 1 && params.page < 3"
              @search="search($event)"
              style="margin-bottom: 10px"
              :products_count="products_count"
              :products="products"
              :params="params"
              :categories="categories"
            ></search-panel-component>
          </div>
           <div v-if="params.step == 2 || (!params.back && params.page >= 2 && params.category_id)" class="col-md-12 my-3">
            <over-all-weighting-component
              :params="params"
              @back="backToStep1($event)"
              @search="search($event)"
            ></over-all-weighting-component>
          </div>
        </div>
        <div v-else >
            <div >
              <over-all-weighting-component
                :params="params"
                @back="backToStep1($event)"
                @search="search($event)"
              ></over-all-weighting-component>
            </div>
        </div>
      </div>
      <div class="col-md-7">

        <div class="row">
          <div :class="(params.step <= 1 && params.back > 0) ? 'col-md-4' : 'col-xxl-4 col-xl-6 col-lg-12'" v-for="product in products" :key="product.id">
            <div :class="flex - container">
              <search-product-preview-component
                :product="product"
                :category_id="params.category_id"
                :step="params.step"
                :volume="params.volume"
                :params="params"
              ></search-product-preview-component>
            </div>
          </div>
        </div>

         <div class="col-md-12" >
          <div :class="params.step >= 2 ? 'container' : 'container'">
            <div class="row">
              <div class="col-md-12" v-if="!products.length && !loading">
                <strong class="text-lg"
                  >Hmmm, We didn't find any match on your search. Please try
                  again</strong
                >
              </div>
            
            </div>

            <hr class="bg-secondary button-container border-2" />
            <div class="row" role="group" v-if="products.length">
              <div class="col-xxl-3 col-xl-4">
                <button
                  class="btn bg-gradient-secondary btn-sm w-100 btn-responsive"
                  v-if="params.step > 1 && params.category_id"
                  @click="confirmDownloadFromBrowser()"
                >
                  <i class="fa-solid fa-file-arrow-down"></i> Download Provisional
                  Report
                </button>
              </div>
              <div class="col-xxl-3 col-xl-4">
                <button
                  class="btn bg-gradient-primary btn-sm w-100 btn-responsive"
                  v-if="params.step > 1 && params.category_id"
                  @click="confirmSendRequirementToSuppliers()"
                  :disabled="params.already_used"
                >
                  Request Final Pricing from Suppliers
                </button>
              </div>

              <div class="col-xxl-3 col-xl-4">
                  <button
                    class="btn bg-gradient-info btn-sm w-100 btn-responsive"
                    v-if="params.step > 1 && params.category_id"
                    @click="saveUrl()"
                  >
                   <i class="fa-regular fa-bookmark"></i> Save this Search Result For Later
                  </button>
                </div>
            </div>
          </div>
        </div>
        
      </div>
      
     
     
    </div>

    <el-drawer v-model="showSavedUrl" :show-close="false"	 title="Saved Searches" :size="'30%'">
      <div class="drawer-footer p-5">
        <saved-searches ref="savedSearchesDrawer" :key="'save_searches_' + keyCounter" :category_id="params.category_id" :url="url" @close="closeSavedUrl()"></saved-searches>
      <hr>
      </div>
    </el-drawer>

    <el-dialog
      id="modal-show"
      v-model="dialogVisible"
      :title="selected ? selected.name : ''"
      width="600"
    >
      <div v-if="selected">
        <div style="font-size:12px;color:#696363">
          Description : {{ selected.description }}
        </div>
        <div style="font-size:12px;color:#696363">
          Price : £ {{ selected.prices }} per
          {{
            selected.quantity > 1
              ? selected.quantity + ' ' + selected.unit
              : ' ' + selected.unit
          }}
        </div>
        <div style="font-size:12px;color:#696363">
          Supplier : {{ selected.supplier.name }}
        </div>
        <div style="font-size:12px;color:#696363">
          Categories : {{ joinedCategories(selected.categories) }}
        </div>
        <div style="font-size:12px;color:#696363">
          Website :
          <a target="_blank" :href="selected.website">{{ selected.website }}</a>
        </div>
        <hr />
        <h5 style="font-weight:bold;font-size: 20px;margin-bottom: 20px">
          Compute the cost per {{ selected.unit }}
        </h5>
        <b-row>
          <b-col md="6" sm="6">
            <b-form>
              <b-form-group
                id="input-group-1"
                label="How much do you need:"
                label-for="input-1"
                description=""
              >
                <b-form-input
                  id="input-1"
                  v-model="user_quantity"
                  type="number"
                  placeholder="0.00"
                  step=".0001"
                  required
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col md="6" sm="6">
            Total Cost :
            <!-- {{ selected.prices }} * ( {{ user_quantity }} / {{ selected.quantity }}) <br> -->
            <div class="total-cost">£ {{ total }}</div>
          </b-col>
        </b-row>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Ok</el-button>
        </div>
      </template>
    </el-dialog>
 
  </div>
</template>

<script>
import { Category } from '@/api_services/category'
import { Product } from '@/api_services/product'
import SearchPanelComponent from './SearchPanelComponent.vue'
import LoadingComponent from '../../common/LoadingComponent.vue'
import SearchProductPreviewComponent from './SearchProductPreviewComponent.vue'
import OverAllWeightingComponent from './OverAllWeightingComponent.vue'
import Swal from 'sweetalert2'
import SavedSearches from './SavedSearches.vue'
import { UserProductSearch } from '@/api_services/user_product_search'
import { ref } from 'vue';

const savedSearchesDrawer = ref(0);

export default {
  data() {
    return {
      loading_title_message: null,
      loading_content_message: null,
      dialogVisible: false,
      duplicate_count: 0,
      savesearchform: {
        label: null,
        url: null
      },
      keyCounter: 0,
      showSavedUrl: false,
      url: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      downloadable_result_link: null,
      params: {
        back: 0,
        page: 1,
        charges: [],
        specs: [],
        subspecs: [],
        social_values: [],
        category_id: null,
        step: 0,
        product_match: 40,
        price: 40,
        social_value: 15,
        environment_impact: 10,
        management_info: 5,
        volume_type: 0,
        volume: 0,
        first_search: true,
        cost_per_unit: 0,
        bcp: parseInt(process.env.VUE_APP_IS_BCP)
      },
      term: null,
      form: { term: null, category_id: null, cost_per_unit: 0 },
      products: [],
      categories: [],
      selected: null,
      user_quantity: 0,
      loading: false,
      products_count: 0,
      step1_products: [],
      step: 1,
      first_load: true,
      first_search: true,
      original_category_id: null,
      bcp_active: false
    }
  },
  components: {
    SearchPanelComponent,
    LoadingComponent,
    SearchProductPreviewComponent,
    OverAllWeightingComponent,
    SavedSearches
  },
  created: function () {
    let qry = Object.assign({}, this.$route.query)
    if (qry.back == '1') qry.back = 1
    if (qry.back == '0') qry.back = 0
    if (typeof qry.specs === 'string') {
      qry.specs = JSON.parse(qry.specs)
      this.products_count = 1
    }

    if (typeof qry.subspecs === 'string') {
      qry.subspecs = JSON.parse(qry.subspecs)
      this.products_count = 1
    }

    if (typeof qry.charges === 'string') {
      qry.charges = JSON.parse(qry.charges)
      this.products_count = 1
    }

    if (typeof qry.social_values === 'string') {
      qry.social_values = JSON.parse(qry.social_values)

      this.products_count = 1
    }

    if (Object.keys(qry).length) {
      this.params = qry
    }

    if (this.$store.state.savedUser) {
      this.bcp_active = this.$store.state.savedUser.bcp_status
    }

    this.getCategories()
  },
  computed: {
    total: function() {
      if (this.selected) {
        return (
          this.selected.prices *
          (this.user_quantity / this.selected.quantity)
        ).toFixed(4)
      }
      return 0
    }
  },
  methods: {
    closeSavedUrl() {
      this.showSavedUrl = false
    },
    saveUrl(type) {
      let self = this

      this.keyCounter++

      if (type == 'list'){
        this.url = null
        this.showSavedUrl = true
      }
      else{
        
        Category.show(this.params.category_id)
            .then(result => {
              if(result.data.category){
                this.savesearchform.url = this.url

                UserProductSearch.get({ params: { label: result.data.category.name } })
                  .then(result => {
                    this.duplicate_count = result.data.user_product_searches.length
                }).catch(error => {
                  this.$toast.error('An error occured while checking for search name duplicates')
                  console.error('error:', error.response.data)
                })
                .finally(() => {
                  
                  this.savesearchform.label = result.data.category.name + ((this.duplicate_count > 0) ? " (" + this.duplicate_count + ")" : "")

                  UserProductSearch.create(this.savesearchform)
                    .then(result => {
                      this.$toast.success('Search saved successfully')
                      this.showSavedUrl = true
                      if(self.$refs.savedSearchesDrawer){
                        self.$refs.savedSearchesDrawer.getUrls()
                      }
                      this.params.user_product_search_id = result.data.user_product_search.id
                      console.log(this.params)
                    })
                    .catch(error => {
                      this.$toast.error('An error occured while saving the url')
                      console.error('error:', error.response.data)
                    })
                })
              }
            })
            .catch(error => {
              this.$toast.error('An error occured while fetching categories')
              console.error('error:', error.response.data)
            })
        
      }
    },
    next() {
      this.params.back = 0
      this.params.step = 2
    },
    back() {
      this.params.back = 1
      this.params.step = 1
    },
    joinedCategories(categories) {
      let string = ''
      categories.forEach(category => {
        string += string.length == 0 ? category.name : ', ' + category.name
      })
      return string
    },
    close() {
      this.dialogVisible = false
      this.user_quantity = 0
      this.selected = null
    },
    closeAndDownload(waiting_time) {
      this.$router.push({ path: '/main/product_search_queries/list', query: waiting_time ? { searched: true } : {} })
    },
    showModal(product) {
      this.selected = Object.assign({}, product)
      this.dialogVisible = true
    },
    updateUrl(params) {
      
      let qry = Object.assign({}, params)
      if (params.specs && params.specs.length)
        qry.specs = JSON.stringify(params.specs)
      if (params.subspecs && params.subspecs.length)
        qry.subspecs = JSON.stringify(params.subspecs)
      if (params.charges.length) qry.charges = JSON.stringify(params.charges)
      if (params.social_values.length)
        qry.social_values = JSON.stringify(params.social_values)

      this.$router.replace(
        {
          query: Object.assign({ ...this.$route.query }, qry)
        },
        () => { }
      )

      this.url = this.$route.fullPath
    },
    backToStep1(params) {
      this.updateUrl(params)
      this.params = Object.assign({}, params)
    },
    async search(params) {
      this.meta.page = params.page
    
      this.meta.page += 1
      if (this.meta.page > 3) this.meta.page = 3

      if (!params.specs) params.specs = []
      if (!params.subspecs) params.subspecs = []
      if (!params.charges) params.charges = []
      if (!params.social_values) params.social_values = []
      this.getProducts(params)
    },
    setSpecDescription(specs) {
      var self = this
      specs.forEach(function(spec) {
        if (spec.specification) {
          spec.original_description = null
          spec.description = spec.specification.description
          spec.unit = spec.specification.unit
        }
      })
      return specs
    },
    gatherAdditionalItems() {
      let charges = []
      let listOfCharges = []
      let self = this

      this.products.forEach(function(product) {
        product.product_price_ranges.forEach(function(priceRange) {
          priceRange.additional_items.forEach(function(item) {
            let obj = { name: item.description }

            item.category_charges.forEach(function(categoryCharge) {
              if (
                !listOfCharges.includes(item.description) &&
                categoryCharge.category_id == self.params.category_id
              ) {
                obj.level_of_importance = categoryCharge.level_of_importance
                if (self.params.back) {
                  if (!self.params.charges) self.params.charges = []
                  self.params.charges.forEach(function(chrg) {
                    if (chrg.name == obj.name)
                      obj.level_of_importance = chrg.level_of_importance
                  })
                }
                charges.push(obj)
                listOfCharges.push(item.description)
              }
            })
          })
        })
      })

      this.params.charges = charges
    },
    gatherSpecsFromProducts() {
      let self = this
      let specs = []
      let spec_names = []

      this.params.specs.forEach(function(spec) {
        if (spec.specification) spec.specification.original_description = null
        specs.push(spec)
        spec_names.push(spec.specification_name)
      })

      let subSpecs = []
      let SubSpecsNames = []

      this.products.forEach(function(product) {
        if (!product.product_specifications) product.product_specifications = []
        product.product_specifications.forEach(function(prod_spec) {
          let spec = {
            specification_name: prod_spec.specification.description,
            condition: '=',
            specific_unit: prod_spec.specification.has_unit
              ? prod_spec.specific_unit
              : '',
            response: prod_spec.response,
            weighing: 1,
            description: prod_spec.specification.description,
            unit: prod_spec.specification.unit,
            specification: prod_spec.specification,
            specification_id: prod_spec.specification.id
          }

          if (!self.params.back && self.params.category_id) {
            prod_spec.category_specifications.forEach(function(catSpec) {
              if (catSpec.category_id == self.params.category_id) {
                spec.weighing = catSpec.level_of_importance
              }
            })
          }

          if (self.params.back) {
            self.params.specs.forEach(function(oneSpec) {
              if (
                oneSpec.specification_name ==
                prod_spec.specification.description
              ) {
                spec.weighing = oneSpec.weighing
              }
            })
          }

          if (!spec_names.includes(prod_spec.specification.description)) {
            if (spec.specification)
              spec.specification.original_description = null
            specs.push(spec)
            spec_names.push(prod_spec.specification.description)
          }
        })
      })

      let requiredSpecs = []
      let requiredSpecNames = []
      this.params.specs.forEach(function(spec) {
        if (spec.required) {
          if (spec.specification) spec.specification.original_description = null
          requiredSpecs.push(spec)
          requiredSpecNames.push(spec.description)
        }
      })

      specs.forEach(function(spec) {
        if (!requiredSpecNames.includes(spec.description)) {
          if (spec.specification) spec.specification.original_description = null
          requiredSpecs.push(spec)
          requiredSpecNames.push(spec.description)
        }
      })
      let no_specs = !this.params.specs.length
      this.params.specs = requiredSpecs

      if (this.params.category_id) this.getDefaultLevelOfImportanceFromSpecs()
    },
    gatherSocialValuesFromProducts() {
      let self = this
      let social_values = []
      let social_value_names = []
      if (!this.params.social_values) this.params.social_values = []

      this.params.social_values.forEach(function(sv) {
        if (!social_value_names.includes(sv.description)) {
          social_values.push(sv)
          social_value_names.push(sv.description)
        }
      })

      this.products.forEach(function(product) {
        if (product.product_social_values.length)
          product.product_social_values.forEach(function(prod_social_value) {
            if (prod_social_value.social_value.description) {
              let social_value = {
                description: prod_social_value.social_value.description,
                condition: '=',
                response: prod_social_value.value,
                level_of_importance: 1, //level of importance
                global_social_value_id:
                  prod_social_value.social_value.global_social_value_id
              }

              if (!self.params.back && self.params.category_id) {
                prod_social_value.social_value.category_social_values.forEach(
                  function(catSv) {
                    if (catSv.category_id == self.params.category_id) {
                      social_value.level_of_importance =
                        catSv.level_of_importance
                    }
                  }
                )
              }

              if (self.params.back) {
                self.params.social_values.forEach(function(oneSV) {
                  if (
                    oneSV.description ==
                    prod_social_value.social_value.description
                  ) {
                    social_value.weighing = oneSV.weighing
                  }
                })
              }

              if (
                !social_value_names.includes(
                  prod_social_value.social_value.description
                )
              ) {
                social_values.push(social_value)
                social_value_names.push(
                  prod_social_value.social_value.description
                )
              }
            }
          })
      })

      this.params.social_values = social_values
    },
    gatherSubSpecsFromProducts() {
      let self = this
      let specs = []
      let spec_names = []

      if (!this.params.subspecs) this.params.subspecs = []
      this.params.subspecs.forEach(function(spec) {
        specs.push(spec)
        spec_names.push(spec.specification_name)
      })

      let subSpecs = []
      let SubSpecsNames = []

      this.products.forEach(function(product) {
        product.product_specifications.forEach(function(parent_prod_spec) {
          parent_prod_spec.sub_prod_specs.forEach(function(prod_spec) {
            let spec = {
              specification_name: prod_spec.specification.description,
              condition: '=',
              specific_unit: prod_spec.specification.has_unit
                ? prod_spec.specific_unit
                : '',
              response: prod_spec.response,
              weighing: 1,
              description: prod_spec.specification.description,
              unit: prod_spec.specification.unit,
              specification: prod_spec.specification,
              specification_id: prod_spec.specification.id
            }

            if (!self.params.back && self.params.category_id) {
              prod_spec.category_specifications.forEach(function(catSpec) {
                if (catSpec.category_id == self.params.category_id) {
                  spec.weighing = catSpec.level_of_importance
                }
              })
            }

            if (self.params.back) {
              self.params.specs.forEach(function(oneSpec) {
                if (
                  oneSpec.specification_name ==
                  prod_spec.specification.description
                ) {
                  spec.weighing = oneSpec.weighing
                }
              })
            }

            if (!spec_names.includes(prod_spec.specification.description)) {
              specs.push(spec)
              spec_names.push(prod_spec.specification.description)
            }
          })
        })
      })

      let requiredSpecs = []
      let requiredSpecNames = []
      this.params.subspecs.forEach(function(spec) {
        if (spec.required) {
          requiredSpecs.push(spec)
          requiredSpecNames.push(spec.description)
        }
      })

      specs.forEach(function(spec) {
        if (!requiredSpecNames.includes(spec.description)) {
          requiredSpecs.push(spec)
          requiredSpecNames.push(spec.description)
        }
      })

      // let no_specs = !this.params.subspecs.length
      // this.params.subspecs = requiredSpecs

      // if (this.params.category_id) this.getDefaultLevelOfImportanceFromSpecs()
    },
    getDefaultLevelOfImportanceFromSpecs() {
      let specIds = []

      this.params.specs.forEach(function(spec) {
        specIds.push(spec.specification_id)
      })

    },
    setDefaultLevelOfImportance(categorySpecs) {
      let self = this

      this.params.specs.forEach(function(spec) {
        categorySpecs.forEach(function(categorySpec) {
          if (spec.specification_id == categorySpec.specification_id) {
            spec.weighing = categorySpec.level_of_importance
          }
        })
      })
    },
    downloadFromBrowser(url) {
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = url.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    confirmDownloadFromBrowser() {
      Swal.fire({
        text:
          'Thank you for requesting a provisional report. Please note that the suppliers will not be notified of your search at this stage and this report does not represent a final offer from them. If you decide to request final pricing from suppliers, the further competition stage will be initiated. Please ensure you browser allows pop-ups and contact our support team if you have any questions.',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.checkIfPopUpBlocked()
          if(!this.checkIfPopUpBlocked()){
            this.download()
          }
          else{
            Swal.fire({
              confirmButtonText: 'I understand',
              icon: "info",
              title: "Oops...",
              text: "Download failed. It appears that your browser's Pop-Up Blocker is enabled, preventing the download. Please disable the Pop-Up Blocker in your browser settings to proceed with the download.",
            });
          }
        }
      })
    },
    confirmSendRequirementToSuppliers() {
      this.checkUserHasPendingRequest()
    },
    async showErrorForPending(message) {

      Swal.fire({
        html: message,
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: 'Manage Procurement Progress',
        cancelButtonText: 'Close',
      }).then(result => {
        this.loading = false
        if (result.isConfirmed) {
          this.$router.replace({
            path: '/main/product_search_queries/list',
          })
        }
      })

    },
    checkUserHasPendingRequest() {
      this.loading_title_message = null
      this.loading_content_message = null
      this.loading = true
      
      let params = this.gatherParams()
      params.check_only = 1
      this.error_message = null
            
      Product.sendResult({ params: params })
        .then(result => {
          if (result.data.error_message) {      
            this.error_message = result.data.error_message
            this.showErrorForPending(this.error_message)
          } else {
            this.confirmedUniqueRequst(result.data.waiting_time)
          }
        })
        .catch(error => {
          this.loading = false
        })
        .finally(() => {
          
        })
    },
    async confirmedUniqueRequst(waiting_time) {
    
       Swal.fire({
          text:
            'Thank you for requesting final pricing from the qualifying suppliers. All suppliers will now be notified and given the opportunity to present their best offers to you at the end of the 10-day further competition period. There is no obligation for you to accept any offers received.',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',  
       }).then(result => {
          this.loading = false
          if (result.isConfirmed) {
            this.sendRequirementToSuppliers(waiting_time)
          }
        })
    },
    async confirmedRequestSubmitted(waiting_time) {

      const { value: res } = await Swal.fire({
        title: 'CPRAS Procurement Platform',
        html: `
              <div style="text-align:center" class="text-sm">
                <p>
                  Thank you for downloading your provisional report from the CPRAS Procurement Platform and requesting final pricing from your qualifying suppliers.
                </p>
                <p>
                  You will now be redirected to your ‘My Procurement Progress’ page where you can review the provisional report, monitor the progress of this search and upload any additional documents required by the suppliers to generate their most competitive final offering.
                </p>
                <p>
                  Please note, in accordance with procurement regulations, if no further documents are required you will now automatically enter a ten-day Further Competition period after which you will receive your final report. If additional documents are required, the Further Competition period cannot be initiated until these are uploaded.
               </p>
                <p>
                  If you have any questions or require further assistance, please contact our support team via the link on your Dashboard. We are here to assist you through every stage of the procurement progress.
                </p>
              </div>
            `,
        icon: 'success',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: true,
        showCancelButton: false,
         showCancelButton: false,
        confirmButtonText: 'Continue to My Procurement Progress'
      })

      if (res) this.closeAndDownload(waiting_time)
    },
    sendRequirementToSuppliers() {
     
      this.loading_title_message = null
      this.loading_content_message = null

      this.loading = true
      let params = this.gatherParams()
      params.check_only = ''
      this.globalState.loading = true
      this.globalState.message = "Processing..."
      Product.sendResult({ params: params })
        .then(result => {
          let self = this
          let waitingTime = result.data.waiting_time
          setTimeout(function () {
            self.$toast.success('Result has been sent to suppliers')
            self.confirmedRequestSubmitted(waitingTime)
          }, waitingTime)
           
        })
        .catch(error => {
          this.globalState.loading = false
          this.$toast.error('An error occurred while sending results')
          console.error('error:', error)
        })
        .finally(() => {
          // this.loading = false
        })
    },
    gatherParams() {
      var productIds = []
      var products_with_points = []

      this.products.forEach(function(product) {
        productIds.push(product.id)
        products_with_points.push({
          product_id: product.id,
          points: product.over_all_points,
          final_points_list: product.final_points_list
        })
      })

      var params = this.params
      params.product_ids = productIds
      params.products_with_points = products_with_points
      params.step1_products = this.step1_products
      params.searched_product_id = this.searched_product_id
      return params
    },
    checkIfPopUpBlocked() {
        var checkpopup = window.open("", '_blank', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=500,height=500');
        if (!checkpopup || checkpopup.closed || typeof checkpopup.closed=='undefined') {
          return true
        }
        checkpopup.close()
        return false
    },
    retryDownload() {
      let self = this
      this.loading = true
      setTimeout(function() {
        console.log('Function executed after 5 seconds');
        self.retries += 1
        self.download()
      }, 5000);
    },
    download() {
      this.loading_title_message = "Generating Provisional Report"
      this.loading_content_message = "Please wait while we are generating your provisional report..."
      this.loading = true
      
      Product.downloadResult({ params: this.gatherParams() })
        .then(result => {

          if (result.data.status == 2) {
            this.loading = false
            this.retries = 0
            this.downloadable_result_link = process.env.VUE_APP_API_ROOT + '/storage' + result.data.url
            this.downloadFromBrowser(this.downloadable_result_link)
          } else if (result.data.status == 1) {
            if (this.retries < 10) {
              this.retryDownload()
            } else {
              this.$toast.open({
                message:
                  'Oops! Our report-making elves are a bit tied up at the moment. Could you please have another go in 3 seconds? Thanks for your patience!',
                type: 'info',
                dismissible: true,
                duration: 5000
              })

              this.retries = 0
              this.loading = false
            }
          } else {
            this.$toast.error('An error occured while downloading the provisional report.')
            this.retries = 0
              this.loading = false
          }
        })
        .catch(error => {
          this.loading = false
          this.$toast.error('An error occured while downloading results')
          console.error('error:', error)
            this.loading = false
        })
        .finally(res => {

        })
    },
    gatherProductIds() {
      var products = []
      this.products.forEach(function(product) {
        products.push({
          product_id: product.id,
          points: product.points
        })
      })

      return products
    },
    checkParams(params) {
      if (params.social_values) {
        params.social_values = params.social_values.filter(n => n)
      }

      return params
    },
    getCategories() {
      Category.get({ params: {} })
        .then(result => {
          if (result.data.categories) this.categories = result.data.categories
          this.getProducts(this.params)
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching categories')
          console.error('error:', error.response.data)
        })
    },
    getProducts(params) {
      if (this.first_load) this.first_load = false
      if (!params.category_id && this.first_load) return

      if (params.submit) params.step = 2
      if (params.back && params.step == 1) return false
      params.page = this.meta.page
      params.public = true

      this.loading_title_message = null
      this.loading_content_message = null
      this.loading = true
      params = this.checkParams(params)
      this.params = params

      Product.get({ params: params })
        .then(result => {
          this.loading = false
          
          if (this.params.first_search && this.params.category_id) {
            this.params.first_search = false
            if (!this.params.back) this.params.volume_type = 0
          }
          if (result.data.meta) this.meta = result.data.meta

          if (result.data.params.subspecs)
            this.params.subspecs = result.data.params.subspecs
          if (result.data.products) {
            this.products = result.data.products
            if (params.step == 0) params.step = 1
            if (params.step < 2) {
              this.step1_products = this.gatherProductIds()
            }
          }
          if (result.data.products_count)
            this.products_count = result.data.products_count

          this.params.specs = result.data.params.specs
          this.params.social_values = result.data.params.social_values

          if (result.data.params.user) this.params.user = null

          this.original_category_id = params.category_id
          if (this.params.category_id && this.params.back && this.params.page > 1) this.params.back = 0

          this.gatherAdditionalItems()
          this.updateUrl(this.params)
          this.searched_product_id = result.data.searched_product_id
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching products')
          console.error('error:', error)
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.sticky-container {
  position: sticky;
  top: 0;
  /* height: 100vh;  */
  /* Adjust the height as per your requirement */
  overflow-y: auto;
  padding: 10px;
}
.custom-tag {
  font-size: 12px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px;
  border-radius: 8px;
  margin: 3px;
}

.total-cost {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #f44336;
}

.orange-btn {
  background: #ff9800;
  color: rgb(8, 8, 8);
}

.orange-btn:hover {
  background: #f3b04c;
  color: rgb(8, 8, 8);
}
@media screen and (max-width: 1650px) and (min-width: 1400px) {
  .col-xxl-4 {
    width: 50%;
  }
}
@media screen and (max-width: 1250px) and (min-width: 1200px) {
  .col-xl-6 {
    width: 100%;
  }
}

@media screen and (max-width: 1860px) and (min-width: 1600px) {
  .btn-responsive {
    height: 70px;
  }
}

@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .btn-responsive {
    height: 105px;
  }
}

@media screen and (max-width: 390px) and (min-width: 300px) {
  .btn-responsive {
    height: 50px;
  }
}

</style>